import React from 'react';
import { ReactComponent as Incidents } from 'assets/images/Incidents.svg';
import { ReactComponent as VehicleFaults } from 'assets/images/Faults.svg';
import { ReactComponent as Surveys } from 'assets/images/Surveys.svg';
import { iconTypes } from 'types/Layout';

type iconStates = 'unselected' | 'hover' | 'active';

type sizes = 'default' | 'lg';
export interface ICustomIcon {
	iconTypes: iconTypes;
	iconState: iconStates;
	size?: sizes;
}

const components = {
	Incidents,
	'Faults': VehicleFaults,
	Surveys,
	Report: Surveys,
};

const CustomIcon: React.FC<ICustomIcon> = ({
	iconTypes,
	iconState,
	size = 'default',
}) => {
	const iconSize = {
		default: 'w-[42px] h-[42px]',
		lg: 'w-[54px] h-[54px]',
	};

	const states = {
		unselected: 'text-[#FAA431] rounded-[64px]',
		hover: '!text-black rounded-[64px]',
		active: '!text-black fill-primary-800 bg-primary-500 rounded-[64px]',
	};
	const Icon = components[iconTypes];
	return (
		<div className={`rounded-[64px] text-[#FAA431] ${iconSize[size]} ${states[iconState]} hover:bg-primary-500 hover:text-black`}>
			<div className="flex flex-col justify-center">
				<Icon
					className={`rounded-[16px] ${iconSize[size]} p-[7px]`}
				/>
			</div>
		</div>
	);
};

export default CustomIcon;
