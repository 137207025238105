import React from 'react';

interface IDivider {
	color?: string;
	className?: string;
}

const Divider: React.FC<IDivider> = ({
	color = 'border-orange-500',
	className = '',
}) => {
	return (
		<div className={`flex items-center ${className}`}>
			<div className={`w-full border-t-[1px] ${color}`} />
		</div>
	);
};

export default Divider;
