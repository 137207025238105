import 'index.css';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import SlideDown from 'components/atoms/SlideDown';
import StatusButton from 'components/atoms/StatusButton';
import IncidentCard from 'components/molecules/IncidentCard';
import SurveyCard from 'components/molecules/SurveyCard';
import VehicleCard from 'components/molecules/VehicleCard';
import React, { useState } from 'react';
import { IVehicleSurveySubmissions } from 'types/Surveys';
import { IVehicleFault } from 'types/VehicleFaults';
import { IVehicleIncident } from 'types/VehicleIncidents';
import { formatName } from 'utils/formatName';

export interface IVehicleRow {
	data: IVehicleFault | IVehicleIncident | IVehicleSurveySubmissions;
	refetchData: () => void;
	hideColumns?: boolean;
}

const VehicleRow: React.FC<IVehicleRow> = ({
	data,
	refetchData,
	hideColumns = false,
}) => {
	const [open, setOpen] = useState(false);
	const [slideDownDisabled, setSlideDownDisabled] = useState(false);
	const [cellStyle, setCellStyle] = useState(
		`whitespace-nowrap px-3 py-4 text-sm text-gray-700 bg-white`
	);
	const [secondRowColor, setSecondRowColor] = useState('bg-gray-200');

	const toggleOpen = () => {
		if (slideDownDisabled) {
			return;
		}

		setSlideDownDisabled(true);

		if (open) {
			setTimeout(() => {
				setCellStyle(
					'whitespace-nowrap px-3 py-4 text-sm text-gray-700 bg-white'
				);
				setSecondRowColor('bg-gray-200');
			}, 300);
		} else {
			// state when open
			setCellStyle(
				'whitespace-nowrap px-3 py-4 text-sm text-secondary bg-primary-50 font-bold'
			);
			setSecondRowColor('bg-primary-50');
		}

		setTimeout(() => setSlideDownDisabled(false), 300);

		setOpen(!open);
	};

	return (
		<>
			<tr
				className={'rounded-md relative table-row cursor-pointer'}
				onClick={toggleOpen}
			>
				<td
					className={`${cellStyle} rounded-tl-[7px] ${
						!open && 'rounded-bl-[7px]'
					}`}
				>
					<div
						className={`side-color h-full w-[5px] absolute left-0 top-0 rounded-tl-[10px] bg-primary ${
							open ? 'w-[5px]' : 'w-0'
						}`}
					/>
					{open ? (
						<ChevronUpIcon className="h-6 w-6" />
					) : (
						<ChevronDownIcon className="h-6 w-6 text-primary-500" />
					)}
				</td>
				{data.type === 'vehicle-fault' && (
					<>
						<td className={`${cellStyle}`}>{data.registration}</td>
						<td className={`${cellStyle}`}>{data.vehicle_type}</td>
						<td className={`${cellStyle}`}>{data.numberOfFaults}</td>
						<td className={`${cellStyle} capitalize`}>{data.priority}</td>
						<td className={`${cellStyle}`}>
							{new Date(data.submitted_at)
								.toLocaleDateString('en-AU', {
									weekday: 'short',
									day: 'numeric',
									month: 'short',
								})
								.replace(',', '')}
						</td>
						<td className={`${cellStyle}`}>{formatName(data.operator_name)}</td>
						<td className={`${cellStyle}`}>
							{data.branch && data.branch.name}
						</td>
						<td className={`${cellStyle}`}>{data.ticket_number}</td>
						<td
							className={`${cellStyle} rounded-tr-[7px] ${
								!open && 'rounded-br-[7px]'
							}`}
							colSpan={10}
						>
							{<StatusButton status={data.status} />}
						</td>
					</>
				)}

				{data.type === 'incident' && (
					<>
						<td className={`${cellStyle}`}>{data.branch?.name}</td>
						<td className={`${cellStyle}`}>{data.id}</td>
						{!hideColumns && (
							<>
								<td className={`${cellStyle}`}>
									{data.incident_section_4?.incident_category &&
									data.incident_section_4?.incident_category.length
										? `${data.incident_section_4.incident_category[0].label}`
										: `No Category`}
								</td>
								<td className={`${cellStyle}`}>
									{new Date(data.incident_section_1?.incident_date)
										.toLocaleDateString('en-AU', {
											weekday: 'short',
											day: 'numeric',
											month: 'short',
										})
										.replace(',', '')}
								</td>
							</>
						)}
						{!hideColumns && (
							<td className={`${cellStyle} capitalize !whitespace-normal`}>
								{data.incident_section_2?.personInvolved &&
								data.incident_section_2.personInvolved.length
									? `${data.incident_section_2.personInvolved[0].personInvolved_name}`
									: ''}
							</td>
						)}

						<td className={`${cellStyle}`}>
							{data.incident_section_4?.severity_rating
								? `${data.incident_section_4?.severity_rating}`
								: `N/A`}
						</td>
						<td className={`${cellStyle}`}>{data.ticket_number}</td>
						<td className={`${cellStyle} !whitespace-normal`}>{data.client}</td>
						<td className={`${cellStyle} capitalize !whitespace-normal`}>
							{data.submitted_by}
						</td>
						<td
							className={`${cellStyle} rounded-tr-[7px] ${
								!open && 'rounded-br-[7px]'
							}`}
							colSpan={10}
						>
							{<StatusButton status={data.special_status || data.status} />}
						</td>
					</>
				)}

				{data.type === 'survey' && (
					<>
						<td
							className={`${cellStyle}`}
						>{`${data.first_name} ${data.last_name}`}</td>
						<td className={`${cellStyle}`}>{data?.survey?.name}</td>
						<td className={`${cellStyle}`}>
							{new Date(data?.survey.sent_at)
								.toLocaleDateString('en-AU', {
									weekday: 'short',
									day: 'numeric',
									month: 'short',
								})
								.replace(',', '')}
						</td>
						<td className={`${cellStyle} capitalize`}>{data?.branch?.name}</td>
						<td
							className={`${cellStyle} rounded-tr-[7px] ${
								!open && 'rounded-br-[7px]'
							}`}
							colSpan={10}
						>
							{<StatusButton status={data.survey_status} />}
						</td>
					</>
				)}
			</tr>
			{/* Slide down row */}
			<tr className={`${secondRowColor} second-row`}>
				<td colSpan={12} className="relative">
					<div
						className={`side-color h-full absolute left-0 top-0 rounded-bl-[10px] bg-primary ${
							open ? 'w-[5px]' : 'w-0'
						}`}
					/>
					<SlideDown open={open}>
						<div className="px-4 pb-2 text-left text-black bg-primary-50">
							{data.type === 'vehicle-fault' && (
								<VehicleCard fault={data} refetchData={refetchData} />
							)}

							{data.type === 'incident' && (
								<IncidentCard incident={data} refetchData={refetchData} />
							)}

							{data.type === 'survey' && (
								<SurveyCard survey={data} refetchData={refetchData} />
							)}
						</div>
					</SlideDown>
				</td>
			</tr>
			{/* This row is to allow for grey spacing in table */}
			<tr className="bg-gray-300 h-1" />
		</>
	);
};

export default VehicleRow;
