import { PlusIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import PersonInvolved from 'components/molecules/PersonInvolved';
import WitnessCard from 'components/molecules/WitnessCard';
import React, { useEffect, useState } from 'react';
import {
	useGetIncidentSection2Mutation,
	useUpdateIncidentSection2Mutation,
} from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import {
	ISection2ErrorStates,
	IVehicleIncidentSection2,
	IVehicleIncidentSection2Payload,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { ISection2Type } from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import Divider from "../../atoms/Divider";

export interface IIncidentSection2 {
	sectionId: number | null;
	incidentSection2: IVehicleIncidentSection2;
	setIncidentSection2: React.Dispatch<
		React.SetStateAction<IVehicleIncidentSection2>
	>;
	setSection2IncidentErrorStates: React.Dispatch<
		React.SetStateAction<ISection2ErrorStates>
	>;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	handleValidate: () => void;
}

interface IWitness {
	id: number;
	witness_name: string;
	witness_position: string;
	witness_task: string;
	witness_phone_number: string;
}

interface IPersonInvolved {
	id: number;
	personInvolved_name: string;
	personInvolved_phone_number: string;
	personInvolved_start_date: Date | undefined;
}

const IncidentSection2: React.FC<IIncidentSection2> = ({
	sectionId,
	incidentSection2,
	setIncidentSection2,
	setSection2IncidentErrorStates,
	setSection,
	handleValidate,
}) => {
	incidentSection2.personInvolved = [
		...incidentSection2.personInvolved.map((person) => {
			return {
				...person,
				personInvolved_start_date: person.personInvolved_start_date ? new Date(person.personInvolved_start_date) : undefined,
			};
		}),
	];

	const [getIncidentSection2, { data, isSuccess }] =
		useGetIncidentSection2Mutation();
	const [updateIncidentSection2] = useUpdateIncidentSection2Mutation();
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [section2, setSection2] =
		useState<IVehicleIncidentSection2>(incidentSection2);
	const [toggle, setToggle] = useState(section2.witness_exists);

	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		setSection2(section2);
	}, [section2]);

	useEffect(() => {
		setIncidentSection2((prevSection2) => {
			return {
				...prevSection2,
				personInvolved: section2.personInvolved,
				witnesses: section2.witnesses,
				witness_exists: toggle,
			};
		});
	}, [
		section2.witnesses,
		section2.personInvolved,
		setIncidentSection2,
		toggle,
	]);

	useEffect(() => {
		setToggle(section2.witness_exists);
	}, [section2.witness_exists]);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection2(sectionId);
		}
	}, [getIncidentSection2, sectionId]);

	const handleSection2Update = (
		property: ISection2Type,
		value: string | Date,
		index?: number
	) => {
		const newSection2 = section2;
		const newWitnesses = [...newSection2.witnesses];
		const newPersonInvolved = [...newSection2.personInvolved];
		if (
			property === 'personInvolved_name' ||
			property === 'personInvolved_start_date' ||
			property === 'personInvolved_phone_number'
		) {
			if (typeof index !== 'undefined') {
				newPersonInvolved[index] = {
					...newPersonInvolved[index],
					[property]: value,
				};
			}
		} else if (
			property === 'witness_name' ||
			property === 'witness_position' ||
			property === 'witness_task' ||
			property === 'witness_phone_number'
		) {
			if (typeof index !== 'undefined') {
				newWitnesses[index] = {
					...newWitnesses[index],
					[property]: value,
				};
			}
		}

		newSection2.personInvolved = newPersonInvolved;
		newSection2.witnesses = newWitnesses;

		setIncidentSection2(newSection2);
	};

	const updatePersonInvolvedPayload = async (
		personInvolved: IPersonInvolved[]
	) => {
		const formattedPersonInvolved = personInvolved.map(
			(person: IPersonInvolved) => {
				return {
					...person,
					personInvolved_start_date:
						person.personInvolved_start_date ? person.personInvolved_start_date.toISOString() : undefined,
				};
			}
		);

		if (!sectionId) {
			return;
		}

		const payload: IVehicleIncidentSection2Payload = {
			...getCurrentPayload(),
			id: sectionId,
			personInvolved: formattedPersonInvolved,
		};
		await updateIncidentSection2(payload);
	};

	const updateWitnessPayload = async (witnesses: IWitness[]) => {
		const formattedWitnesses = witnesses.map((witness: IWitness) => {
			return {
				...witness,
			};
		});

		if (!sectionId) {
			return;
		}

		const payload: IVehicleIncidentSection2Payload = {
			...getCurrentPayload(),
			id: sectionId,
			witness_exists: toggle,
			witnesses: formattedWitnesses,
		};

		await updateIncidentSection2(payload);
	};

	const getCurrentPayload = () => {
		const formattedPersonInvolved = section2.personInvolved.map(
			(person: IPersonInvolved) => {
				return {
					...person,
					personInvolved_start_date:
						person.personInvolved_start_date ? person.personInvolved_start_date.toISOString() : undefined,
				};
			}
		);

		const formattedWitnesses = section2.witnesses.map((witness: IWitness) => {
			return {
				...witness,
			};
		});

		return {
			...section2,
			personInvolved: formattedPersonInvolved,
			witnesses: formattedWitnesses,
		};
	};

	useEffect(() => {
		if (!data) {
			return;
		}

		if (isSuccess && data) {
			const { personInvolved, witness_exists, witnesses } = data?.data;

			const formatWitness = witnesses?.map((witness: IWitness) => {
				return {
					...witness,
				};
			});

			const formatPersonInvolved = personInvolved?.map(
				(person: IPersonInvolved) => {
					return {
						...person,
						personInvolved_start_date: person.personInvolved_start_date ? new Date(
							person.personInvolved_start_date
						) : undefined,
					};
				}
			);

			setSection2({
				...section2,
				personInvolved:
					typeof personInvolved !== 'undefined' && personInvolved.length > 0
						? formatPersonInvolved
						: section2.personInvolved,
				witness_exists,
				witnesses:
					typeof witnesses !== 'undefined' && witnesses.length > 0
						? formatWitness
						: section2.witnesses,
			});

			setToggle(witness_exists);
		}
		//removes dependency for "section 2"
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const addWitness = async () => {
		if (disableIncidentWriteAccess) return;
		const { witnesses } = section2;
		let assignId = 1;
		if (witnesses && witnesses.length > 0) {
			assignId = witnesses[witnesses.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		const addWitness = [
			...section2.witnesses,
			{
				id: assignId,
				witness_name: '',
				witness_position: '',
				witness_task: '',
				witness_phone_number: '',
			},
		];

		setSection2((section2) => {
			return {
				...section2,
				witnesses: addWitness,
			};
		});

		updateWitnessPayload(addWitness);
	};

	const deleteWitness = async (id: number) => {
		if (disableIncidentWriteAccess) return;

		const filteredList = section2.witnesses.filter(
			(witness) => witness.id !== id
		);
		setSection2((prevSection2) => {
			return {
				...prevSection2,
				witnesses: filteredList,
			};
		});

		updateWitnessPayload(filteredList);
	};

	const addPersonInvolved = async () => {
		if (disableIncidentWriteAccess) return;
		const { personInvolved } = section2;
		let assignId = 1;
		if (personInvolved && personInvolved.length > 0) {
			assignId = personInvolved[personInvolved.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		const addPersonInvolved = [
			...section2.personInvolved,
			{
				id: assignId,
				personInvolved_name: '',
				personInvolved_phone_number: '',
				personInvolved_start_date: undefined,
			},
		];

		setSection2((section2) => {
			return {
				...section2,
				personInvolved: addPersonInvolved,
			};
		});

		updatePersonInvolvedPayload(addPersonInvolved);
	};

	const deletePersonInvolved = async (id: number) => {
		if (disableIncidentWriteAccess) return;

		const filteredList = section2.personInvolved.filter(
			(person) => person.id !== id
		);
		setSection2((prevSection2) => {
			return {
				...prevSection2,
				personInvolved: filteredList,
			};
		});

		updatePersonInvolvedPayload(filteredList);
	};

	const handleOnBlur = async () => {
		if (!sectionId || disableIncidentWriteAccess) {
			return;
		}

		const formattedWitnesses = section2?.witnesses.map((witness) => {
			return {
				...witness,
			};
		});

		const formattedPersonInvolved = section2?.personInvolved.map((person) => {
			return {
				...person,
				personInvolved_start_date:
					person.personInvolved_start_date ? person.personInvolved_start_date.toISOString() : undefined,
			};
		});

		const payload: IVehicleIncidentSection2Payload = {
			id: sectionId,
			witness_exists: toggle,
			witnesses: toggle ? formattedWitnesses : [],
			personInvolved: formattedPersonInvolved,
		};

		await updateIncidentSection2(payload);
	};
	return (
		<>
			<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">Person Involved</Heading>
			<form>
				{/* Person Involved */}
				<>
					{section2.personInvolved.length > 0 &&
						section2.personInvolved.map((person, index) => {
							const { id } = person;
							return (
								<PersonInvolved
									key={id}
									handleSection2Update={handleSection2Update}
									onBlur={handleOnBlur}
									deletePersonInvolved={deletePersonInvolved}
									id={id}
									personInvolved={person}
									index={index}
									isDisabled={disableIncidentWriteAccess}
								/>
							);
						})}

					<Button
						onClick={addPersonInvolved}
						type="secondary"
						className="font-bold mr-4 !rounded-3xl px-[24px] py-[10px] tracking-[1.92px]"
						isDisabled={disableIncidentWriteAccess}
					>
						<PlusIcon height={18} width={18} className="mr-2" />
						Add another person involved
					</Button>
				</>

				<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
				<ToggleButtonV2
					toggle={toggle}
					setToggle={setToggle}
					onBlur={handleOnBlur}
					label="Were there any witnesses?"
					isDisabled={disableIncidentWriteAccess}
				/>
				{toggle && (
					<>
						{section2.witnesses.length > 0 &&
							section2.witnesses.map((witness, index) => {
								const { id } = witness;
								return (
									<WitnessCard
										key={id}
										handleSection2Update={handleSection2Update}
										onBlur={handleOnBlur}
										deleteWitness={deleteWitness}
										id={id}
										witness={witness}
										index={index}
										isDisabled={disableIncidentWriteAccess}
									/>
								);
							})}

						<Button
							onClick={addWitness}
							type="secondary"
							className="font-bold mr-4 !rounded-3xl px-[24px] py-[10px] tracking-[1.92px]"
							isDisabled={disableIncidentWriteAccess}
						>
							<PlusIcon height={18} width={18} className="mr-2" />
							Add another witness
						</Button>
					</>
				)}

				<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
				<div className="flex justify-end mb-[64px]">
					<Button
						onClick={() => {
							handleValidate();
							setSection('section1');
						}}
						type="primary"
						className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
					>
						Back to Description
					</Button>
					<Button
						onClick={() => {
							handleValidate();
							setSection('section3');
						}}
						type="primary"
						className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
					>
						Continue to Incident Injury Details
					</Button>
				</div>
			</form>
		</>
	);
};

export default IncidentSection2;
