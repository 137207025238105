import React, { useState, useEffect, useRef } from 'react';

export interface ITextareaInput {
	id?: string;
	placeholder?: string;
	label?: string;
	onChange: (item: string) => void;
	value: number | string;
	defaultValue?: number | string;
	helpText?: string;
	required?: boolean;
	errorMessage?: string;
	cornerHint?: string;
	isDisabled?: boolean;
	className?: string;
	size?: 'normal' | 'small';
	rows?: number;
	cols?: number;
	resize?: boolean;
	onBlur?: () => void;
}

const TextareaInput: React.FC<ITextareaInput> = ({
	label,
	placeholder,
	id,
	value,
	defaultValue,
	onChange,
	helpText,
	required = false,
	errorMessage,
	cornerHint,
	isDisabled = false,
	className = '',
	size = 'normal',
	rows = 2,
	cols = 1,
	resize = true,
	onBlur,
}) => {
	const [stateValue, setStateValue] = useState(value);
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	useEffect(() => {
		setStateValue(value);
	}, [value]);

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = 'auto';
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [stateValue, resize, rows]);

	const handleOnChange = (val: string) => {
		setStateValue(val);
		onChange(val);
		if (textareaRef.current) {
			textareaRef.current.style.height = 'auto';
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	};

	const handleOnBlur = () => {
		if (onBlur) onBlur();
	};

	const inputBaseStyle = errorMessage
		? 'py-3 block w-full border-red-300 focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-sm text-primary placeholder-red-300 focus:outline-none'
		: 'py-3 block w-full border-gray-300 focus:ring-gray-300 focus:border-gray-300 rounded-sm focus:border-primary';

	return (
		<div className={`${className}`}>
			{label && (
				<div className={cornerHint && 'flex justify-between'}>
					<label
						htmlFor={id}
						className="block font-inter text-base font-semibold tracking-normal !text-secondary-900 mb-[12px]"
					>
						{label} {required && <span className="text-red">*</span>}
					</label>
					{cornerHint && (
						<span className="text-base text-gray-500" id="email-optional">
							{cornerHint}
						</span>
					)}
				</div>
			)}
			{helpText && (
				<p className="mt-2 text-sm text-gray-500 mb-[12px]" id={`${id}-description`}>
					{helpText}
				</p>
			)}
			<div className={'mt-1 relative rounded-md shadow-sm mb-[24px]'}>
				<textarea
					ref={textareaRef}
					name={id}
					id={id}
					defaultValue={defaultValue}
					value={stateValue}
					disabled={isDisabled}
					onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
						handleOnChange(e.target.value);
					}}
					onBlur={handleOnBlur}
					required={required}
					style={{
						height: resize ? 'auto' : '100%',
						minHeight: `${rows * 20}px`,
						overflow: 'hidden',
						resize: 'none',
					}}
					className={`${inputBaseStyle} ${!resize && 'resize-none'}`}
					placeholder={placeholder}
					rows={rows}
					cols={cols}
				/>
			</div>
			{errorMessage && (
				<p className="mt-2 text-sm text-red-600" id="email-error">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

export default TextareaInput;
