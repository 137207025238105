import Text from 'components/atoms/Text';
import React from 'react';

export interface IToggleButtonV2 {
    containerClassName?: string;
    className?: string;
    toggle: boolean;
    setToggle?: React.Dispatch<React.SetStateAction<boolean>>;
    onToggle?: (value: boolean) => void;
    label?: string;
    required?: boolean;
    isDisabled?: boolean;
    onBlur?: () => void;
    margins?: string;
}

const ToggleButtonV2: React.FC<IToggleButtonV2> = ({
                                                   containerClassName = '',
                                                   className = '',
                                                   toggle = false,
                                                   setToggle,
                                                   onToggle,
                                                   label = '',
                                                   required = false,
                                                   isDisabled = false,
                                                   onBlur,
                                                   margins = 'my-8',
                                               }) => {
    const backgroundColor = toggle
        ? 'bg-primary-500 border-transparent flex-row pl-2 pr-[1px]'
        : 'bg-[#EEE] border-secondary flex-row-reverse pl-[1px] pr-2';
    const translateDot = toggle
        ? 'bg-white'
        : 'bg-white';
    const translateText = toggle
        ? 'text-white'
        : 'text-secondary-900';

    const handleOnToggle = () => {
        if (!isDisabled) {
            if (setToggle) {
                setToggle(!toggle);
            } else if (onToggle) {
                onToggle(!toggle);
            }
        }
    };

    return (
        <div className={`flex flex-row-reverse justify-end gap-[10px] ${margins} ${containerClassName}`}>
            {label && (
                <div className="flex flex-col justify-center">
                    <Text className="!text-secondary-900 mr-6" type="bold">
                        {label} {required && <span className="text-red">*</span>}
                    </Text>
                </div>
            )}

            <button
                onClick={handleOnToggle}
                type="button"
                aria-hidden="true"
                className={`${backgroundColor} ${className} flex h-[30px] w-[76px] border-[1px] gap-[6px]  
			rounded-full transition-colors ease-in-out duration-200 justify-evenly !border-secondary-100
			${!isDisabled && 'cursor-pointer'}`}
                onBlur={onBlur}
            >
                <div className="flex flex-col justify-center">
                    <div className={`${translateText} font-bold uppercase mt-[2px]`}>
                        {toggle ? 'Yes' : 'No'}
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <span
                        className={`${translateDot} pointer-events-none h-5 w-5 rounded-full shadow transform ring-0 transition ease-in-out duration-200 mt-[4px]`}
                    ></span>
                </div>
            </button>
        </div>
);
};

export default ToggleButtonV2;
