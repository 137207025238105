import React, { useState, useEffect } from 'react';
import { EyeIcon, EyeOffIcon, XIcon } from '@heroicons/react/solid';
import './input.css';

export interface IIcon {
	className: string;
}

export interface IInput {
	type: string;
	id?: string;
	placeholder?: string;
	label?: string;
	onChange?: any;
	value: number | string;
	defaultValue?: number | string;
	helpText?: string;
	required?: boolean;
	errorMessage?: string;
	cornerHint?: string;
	passwordHideShow?: boolean;
	Icon?: React.ComponentType<IIcon>;
	iconPosition?: 'leading' | 'trailing';
	clearIcon?: boolean;
	isDisabled?: boolean;
	className?: string;
	size?: 'normal' | 'small';
	rounded?: 'medium' | 'small';
	iconClassName?: string;
	onBlur?: () => void;
	inputClassName?: string;
}

const Input: React.FC<IInput> = ({
	label,
	placeholder,
	type,
	id,
	value,
	defaultValue,
	onChange,
	helpText,
	required = false,
	errorMessage,
	cornerHint,
	passwordHideShow = false,
	Icon,
	iconClassName = 'text-gray-500',
	iconPosition = 'leading',
	clearIcon = false,
	isDisabled = false,
	className = '',
	size = 'normal',
	rounded = 'small',
	onBlur,
	inputClassName = '',
}) => {
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [stateValue, setValue] = useState(value);
	useEffect(() => {
		setValue(value);
	}, [value]);

	const handleOnChange = (val: string) => {
		setValue(val);
		onChange?.(val);
	};

	const handleOnBlur = () => {
		if (!onBlur) return;
		onBlur();
	};

	const inputBaseStyle = errorMessage
		? `py-3 block w-full border-red-300 focus:ring-red-500 focus:border-red-500 sm:text-sm text-primary placeholder-red-300 focus:outline-none`
		: 'py-3 block w-full border-gray-300 focus:ring-gray-300 focus:border-gray-300 focus:border-primary';

	const isLeadingIcon = () => {
		return Icon && iconPosition === 'leading';
	};

	return (
		<div className={`${className}`}>
			{label && (
				<div className={cornerHint && 'flex justify-between'}>
					<label
						htmlFor={id}
						className="block font-inter text-base font-bold tracking-normal text-secondary-900 mb-[12px]"
					>
						{label} {required && <span className="text-red">*</span>}
					</label>
					{cornerHint && (
						<span className="text-sm text-gray-500" id="email-optional">
							{cornerHint}
						</span>
					)}
				</div>
			)}
			<div className={'relative rounded-md shadow-sm'}>
				{Icon && (
					<div
						className={`absolute inset-y-0 ${
							isLeadingIcon() ? 'left-0 pl-3' : 'right-0 pr-3'
						} flex items-center pointer-events-none`}
					>
						{<Icon className={`h-5 w-5 ${iconClassName}`} />}
					</div>
				)}
				{clearIcon && value.toString().length > 0 && (
					<div
						className={`absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer`}
					>
						<XIcon
							className="h-5 w-5 text-gray-500"
							onClick={() => handleOnChange('')}
						/>
					</div>
				)}

				<input
					type={showPassword ? 'text' : type}
					name={id}
					id={id}
					defaultValue={defaultValue}
					value={stateValue}
					disabled={isDisabled}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleOnChange(e.target.value);
					}}
					onBlur={handleOnBlur}
					required={required}
					className={`${inputBaseStyle} ${inputClassName} ${
						isLeadingIcon() ? 'pl-10' : 'pr-10'
					} ${size === 'normal' ? 'h-12' : 'h-8'} ${
						rounded === 'medium' ? 'rounded-md' : 'rounded-sm'
					}`}
					placeholder={placeholder}
				/>
				{passwordHideShow && (
					<div
						className={`absolute inset-y-0 right-0 pr-3 flex items-center`}
						onClick={() => setShowPassword(!showPassword)}
					>
						{showPassword ? (
							<EyeIcon className="h-5 w-5 text-gray-500" />
						) : (
							<EyeOffIcon className="h-5 w-5 text-gray-500" />
						)}
					</div>
				)}
			</div>
			{helpText && (
				<p className="mt-2 text-sm text-gray-500" id={`${id}-description`}>
					{helpText}
				</p>
			)}
			{errorMessage && (
				<p className="mt-2 text-sm text-red-600" id="email-error">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

export default Input;
