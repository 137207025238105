import { MenuIcon } from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Select from 'components/atoms/Select';
import Text from 'components/atoms/Text';
import React, { useState } from 'react';
import { useAppDispatch } from 'redux/hooks';
import { useAppSelector } from 'redux/hooks';
import { updateSurveyType } from 'redux/slices/surveys';
import { INavigation } from 'types/Layout';
import { surveyTypeOptions } from 'types/Surveys';

export interface IHeader {
	data?: INavigation;
	onLogout: () => void;
	surveyDropdown?: boolean;
	setFilters?: React.Dispatch<React.SetStateAction<string>>;
	isSidebarHidden: boolean;
	onToggleSidebar: () => void;
}

const Header: React.FC<IHeader> = ({
	data = null,
	onLogout,
	surveyDropdown,
	setFilters,
	isSidebarHidden,
	onToggleSidebar,
}) => {
	const user = useAppSelector((state) => state.user);
	const surveyState = useAppSelector((state) => state.surveys.surveyType);
	const [surveyType, setSurveyType] = useState(surveyState);
	const dispatch = useAppDispatch();

	const handleSurveyChange = (value: string) => {
		// Reset the query string if we have updated the survey type dropdown
		if (setFilters) {
			setFilters('');
		}
		setSurveyType(value);
		dispatch(updateSurveyType(value));
	};

	return (
		<div className="flex flex-row w-full h-[108px] py-7 px-[50px] gap-x-8 border-b border-gray-300 z-40 bg-white">
			<div className="flex flex-1 space-x-8">
				{data ? (
					<>
						<div className="xl:hidden flex">
							<Button
								type="icon"
								className={`transition ease-in-out ${
									isSidebarHidden ? 'rotate-0' : 'rotate-90'
								}`}
								onClick={onToggleSidebar}
							>
								<MenuIcon color="gray" width={20} />
							</Button>
						</div>
						<div className="my-auto uppercase">
							<Heading type="h1" className="xl:-ml-8 tracking-[2.72px]">
								{data.name}
							</Heading>
						</div>
						{surveyDropdown && (
							<Select
								options={surveyTypeOptions}
								onChange={(value: string) => handleSurveyChange(value)}
								className="w-48 z-[35]"
								selected={surveyType}
							/>
						)}
					</>
				) : (
					<Heading type="h1" bold={false} className="flex items-center">
						<div className="xl:hidden flex mr-6">
							<Button
								type="icon"
								className={`transition ease-in-out ${
									isSidebarHidden ? 'rotate-0' : 'rotate-90'
								}`}
								onClick={onToggleSidebar}
							>
								<MenuIcon color="gray" width={20} />
							</Button>
						</div>
						Welcome Back, <b>{user.firstName}</b>
					</Heading>
				)}
			</div>
			<div className="flex flex-row h-[50px] my-auto gap-x-4">
				<button className="m-auto" onClick={onLogout}>
					<Text>Logout</Text>
				</button>
			</div>
		</div>
	);
};

export default Header;
