import React from "react";
import {IconPropTypes} from "./ErrorSolid";

const UploadIconNew: React.FC<IconPropTypes> = ({color='#6B7280', width='20', height='20'}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_6404_23318)">
                <path
                    d="M14.707 4.40371L10.707 0.301308C10.6141 0.205798 10.5038 0.130021 10.3823 0.0783172C10.2608 0.0266138 10.1305 0 9.999 0C9.86747 0 9.73722 0.0266138 9.61573 0.0783172C9.49424 0.130021 9.38389 0.205798 9.291 0.301308L5.291 4.40371C5.10349 4.59629 4.99826 4.85737 4.99844 5.12953C4.99863 5.40169 5.10423 5.66262 5.292 5.85493C5.47977 6.04724 5.73434 6.15517 5.99971 6.15498C6.26507 6.15478 6.51949 6.04648 6.707 5.8539L9 3.5022V12.308C9 12.58 9.10536 12.8409 9.29289 13.0332C9.48043 13.2255 9.73478 13.3336 10 13.3336C10.2652 13.3336 10.5196 13.2255 10.7071 13.0332C10.8946 12.8409 11 12.58 11 12.308V3.5022L13.293 5.8539C13.4816 6.04073 13.7342 6.1441 13.9964 6.14176C14.2586 6.13943 14.5094 6.03157 14.6948 5.84141C14.8802 5.65126 14.9854 5.39402 14.9877 5.12511C14.99 4.85621 14.8892 4.59714 14.707 4.40371Z"
                    fill={color}/>
                <path
                    d="M18 11.7952H13V12.308C13 13.124 12.6839 13.9066 12.1213 14.4836C11.5587 15.0606 10.7956 15.3848 10 15.3848C9.20435 15.3848 8.44129 15.0606 7.87868 14.4836C7.31607 13.9066 7 13.124 7 12.308V11.7952H2C1.46957 11.7952 0.960859 12.0113 0.585786 12.396C0.210714 12.7807 0 13.3024 0 13.8464V17.9488C0 18.4928 0.210714 19.0145 0.585786 19.3992C0.960859 19.7839 1.46957 20 2 20H18C18.5304 20 19.0391 19.7839 19.4142 19.3992C19.7893 19.0145 20 18.4928 20 17.9488V13.8464C20 13.3024 19.7893 12.7807 19.4142 12.396C19.0391 12.0113 18.5304 11.7952 18 11.7952ZM15.5 17.9488C15.2033 17.9488 14.9133 17.8586 14.6666 17.6895C14.42 17.5205 14.2277 17.2802 14.1142 16.9991C14.0007 16.718 13.9709 16.4087 14.0288 16.1103C14.0867 15.8119 14.2296 15.5377 14.4393 15.3226C14.6491 15.1074 14.9164 14.9609 15.2074 14.9016C15.4983 14.8422 15.7999 14.8727 16.074 14.9891C16.3481 15.1055 16.5824 15.3027 16.7472 15.5557C16.912 15.8087 17 16.1061 17 16.4104C17 16.8184 16.842 17.2097 16.5607 17.4982C16.2794 17.7867 15.8978 17.9488 15.5 17.9488Z"
                    fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_6404_23318">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default UploadIconNew
