import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import Heading from 'components/atoms/Heading';
import { ISearch } from 'components/molecules/Search/Search';

const InPageSearch: React.FC<ISearch> = ({
	open,
	setOpen,
	searchType,
	searchHeading,
	children,
}) => {
	return (
		<>
			{open && (
				<div className="h-full w-[415px] min-w-[415px] max-w-[415px] py-10 shadow-xl relative border-r border-gray-300">
					<div className="mx-auto w-full px-6">
						{searchType === 'fault' ||
						searchType === 'incident' ||
						searchType === 'survey' ? (
							<div className="flex items-start justify-between">
								<Heading
									type="h2"
									className="text-[26px] font-bold tracking-[2.08px] "
								>
									SEARCH BY
								</Heading>
								<div className="ml-3 flex h-7 items-center my-auto">
									<button
										type="button"
										className="rounded-md text-orange-500 hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 mx-auto"
										onClick={() => setOpen(false)}
									>
										<span className="sr-only">Close panel</span>
										<XIcon className="h-6 w-6" aria-hidden="true" />
									</button>
								</div>
							</div>
						) : (
							<div>
								<Heading type="h2" colour="primary">
									{searchHeading}
								</Heading>
								<Heading type="h2">Feedback Report</Heading>
							</div>
						)}
					</div>
					<div className="flex flex-col flex-1 px-4 sm:px-6 space-y-4 h-full pt-6 justify-items-start">
						{children}
					</div>
				</div>
			)}
		</>
	);
};

export default InPageSearch;
