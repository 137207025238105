import { SearchIcon } from '@heroicons/react/solid';
import Button from 'components/atoms/Button';
import DateInput from 'components/atoms/DateInput';
import Divider from 'components/atoms/Divider';
import Input from 'components/atoms/Input';
import Accordion from 'components/molecules/Accordion';
import InPageSearchCheckBoxGroup from 'components/molecules/InPageSearchCheckBoxGroup';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useGetVehicleIncidentsMutation } from 'redux/api/vehicleIncidents';
import { useAppSelector } from 'redux/hooks';
import { updateIncidentFilterState } from 'redux/slices/vehicleIncidentsSlice';
import { IQueryPeriod, initialFilterState } from 'types/VehicleIncidents';
import { filtersToQueryStringConverter } from 'utils/filters';

export interface IIncidentSearchBar {
	setFilters: React.Dispatch<React.SetStateAction<string>>;
	setPeriod: React.Dispatch<React.SetStateAction<IQueryPeriod>>;
}

const IncidentSearchBar: React.FC<IIncidentSearchBar> = ({
	setFilters,
	setPeriod,
}) => {
	const dispatch = useDispatch();

	const filters = useAppSelector((state) => state.vehicleIncident.filters);
	const [getIncidents, { data }] = useGetVehicleIncidentsMutation();
	const [filterData, setFilterData] = useState(data?.sortData);
	const [defaultDate] = useState(new Date());

	useEffect(() => {
		getIncidents('all');
	}, [getIncidents]);

	useEffect(() => {
		setFilterData(data?.sortData);
	}, [data?.sortData]);

	useEffect(() => {
		if (data?.sortData && filterData !== undefined) {
			const stateData = data?.sortData[0]?.state;
			const branchData = data?.sortData[1]?.branch;

			const filterState = JSON.parse(JSON.stringify(filters));
			filterState['state'] = stateData;
			filterState['branch'] = branchData;
			dispatch(updateIncidentFilterState(filterState));
		}
		//removes dependency for "filters"
		// eslint-disable-next-line
	}, [setFilterData, filterData, dispatch, data?.sortData]);

	const onClick = () => {
		const query = filtersToQueryStringConverter(filters);
		setFilters(query);
		setPeriod('all');
		//dispatch(resetIncidentFilterState()); // May need this again soon - Ronnie
	};

	const onHandleChange = (
		filterType: string,
		value: React.ChangeEvent<HTMLInputElement> | string | Date
	) => {
		const filterState = JSON.parse(JSON.stringify(filters));
		if (value instanceof Date) {
			filterState[filterType] = value.toISOString();
		} else {
			filterState[filterType] = value;
		}

		dispatch(updateIncidentFilterState(filterState));
	};

	const resetFilters = () => {
		const stateData = data?.sortData[0]?.state || [];
		const branchData = data?.sortData[1]?.branch || [];

		const newFilters = {
			...initialFilterState,
			state: stateData,
			branch: branchData,
		};

		dispatch(updateIncidentFilterState(newFilters));
	};

	return (
		<>
			<div className="flex flex-col grow overflow-y-scroll border-orange-500 border-t-[1px]">
				<div className="flex flex-col h-full">
					<div className={`flex flex-col w-full justify-center my-4 mx-auto`}>
						<Input
							type="text"
							onChange={(value: string) => {
								onHandleChange('keyword', value);
							}}
							value={filters['keyword'] as string}
							placeholder="Keyword search"
							size="normal"
							className="mt-1"
							Icon={SearchIcon}
						/>
					</div>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Date Range" width="w-full" isOpen={true}>
						<div className="w-full grid gap-2 grid-col grid-cols-2">
							<DateInput
								inputLabel="From"
								placeholder="From"
								filterDateType="incident"
								iconClassName="text-secondary"
								selected={defaultDate}
							/>
							<DateInput
								inputLabel="To"
								placeholder="To"
								filterDateType="incident"
								iconClassName="text-secondary"
								selected={defaultDate}
							/>
						</div>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="State" width="w-full" isOpen={true}>
						<InPageSearchCheckBoxGroup
							filterName="state"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Branch" width="w-full" isOpen={true}>
						<InPageSearchCheckBoxGroup
							filterName="branch"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Severity" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="severity"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Incident Category" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="incidentCategory"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Incident Mechanism" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="incidentMechanism"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Incident Agency" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="incidentAgency"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Life Saving Rules" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="lifeSavingRules"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Restricted Injuries" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="restrictedInjury"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Intervention" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="trafficControllersIntervened"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Work Ceased" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="workCeasedUnsafeConditions"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Time Quadrant" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="incidentTimeQuadrant"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Code of Conduct" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="codeOfConduct"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Corrective Actions Severity" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="correctiveActionsPriority"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Corrective Actions" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="correctiveActions"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<Divider color="border-[#DDDDDD] !border-t-[2px]" />
					<Accordion title="Status" width="w-full">
						<InPageSearchCheckBoxGroup
							filterName="status"
							filterType="incident"
							allCheckBox
						/>
					</Accordion>
					<div className="flex-grow"></div>
				</div>
			</div>
			<div className="flex justify-between w-full mt-auto flex-none shrink border-orange-500 border-t-2">
				<Button
					className="h-16 w-[115px] mx-auto my-6 font-bold tracking-[1.92px] rounded-[29px]"
					onClick={resetFilters}
					type="secondary"
				>
					Clear
				</Button>
				<Button
					className="h-16 w-[212px] mx-auto my-6 font-bold tracking-[1.92px] !rounded-3xl"
					onClick={onClick}
				>
					Apply
				</Button>
			</div>
		</>
	);
};

export default IncidentSearchBar;
