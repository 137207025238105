import {
	Document,
	Image,
	Page,
	StyleSheet,
	Text,
	View,
	Font,
} from '@react-pdf/renderer';
import { IIncident } from 'types/VehicleIncidents';
import { IPdfCompanyLogo } from 'types/PdfCompanyLogo';
import moment from 'moment';

/**
 * Interfaces
 */

export interface IPdfDocument {
	incident: IIncident['incidentData'];
	logos: IPdfCompanyLogo[];
}

export interface IPdfDocumentSectionHeader {
	boldText: string;
	text: string;
}

export interface IPdfDocumentSectionData {
	title: string;
	value: string | undefined;
}

export interface IPdfDocumentCheckboxValue {
	label?: string;
	title?: string;
	checked?: boolean;
	is_selected?: boolean;
}

export interface IPdfDocumentSectionDataList {
	title: string;
	value: IPdfDocumentCheckboxValue[];
	labelField?: 'title' | 'label';
	itemWidth?: 'full' | 'third';
	checkedIndex?: 'checked' | 'is_selected';
}

/**
 * Bootstrapping font and styles
 */

Font.register({
	family: 'Inter',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
			fontWeight: 100,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
			fontWeight: 200,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
			fontWeight: 300,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
			fontWeight: 400,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
			fontWeight: 700,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
			fontWeight: 800,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
			fontWeight: 900,
		},
	],
});
/*
Font.register({
	family: 'Inter',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cK_NNeMiJVP86N41Ia-GA.woff2',
			fontWeight: 400,
		},
		{
			src: 'https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cK_NNeMiJVP86N41Ia-GA.woff2',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cJhM9eMiJVP86N41Ia-GA.woff2',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cJYM9eMiJVP86N41Ia-GA.woff2',
			fontWeight: 700,
		},
		{
			src: 'https://fonts.gstatic.com/s/wixmadefortext/v13/-W_oXI_oSymQ8Qj-Apx3HGN_Hu1RTCk5FtSDETgf0cK_NNeMiJVP86N41Ia-GA.woff2',
			fontWeight: 800,
		},
	],
});*/

const styles = StyleSheet.create({
	// General Styles
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		paddingVertical: 20,
		paddingHorizontal: 20,
		fontFamily: 'Inter',
		height: '100%',
	},

	// Header Styles
	headerContainer: {
		flexDirection: 'row',
		alignItems: 'flex-start',
		justifyContent: 'flex-end',
		width: '100%',
	},
	leftLogoContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-end',
		paddingBottom: 10,
		width: '45%',
	},
	rightLogoContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
		paddingBottom: 10,
		width: '55%',
		height: '100%',
	},
	rightLogoBottomAlign: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: 'auto',
		alignItems: 'center',
		justifyContent: 'center',
		borderWidth: 1,
		borderColor: '#F6F6F6',
		padding: '10px',
		flexWrap: 'wrap',
	},
	logo: {
		height: 50,
		width: 200,
	},
	bottomLogo: {
		width: '17%',
		marginHorizontal: 4,
	},
	separator: {
		borderBottom: 2,
		borderColor: '#fe9f00',
		marginVertical: 10,
		width: '100%',
	},
	title: {
		fontSize: 40,
		fontWeight: 700,
		fontFamily: 'Inter',
		marginBottom: 0,
		letterSpacing: -1,
	},
	titleContainer: {
		marginTop: 10,
	},

	// Section Styles
	sectionSeparator: {
		borderBottom: 2,
		borderColor: '#fe9f00',
		marginVertical: 20,
		width: '100%',
	},
	sectionHeader: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '100%',
		marginTop: 10,
		backgroundColor: '#FE9F00',
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		paddingVertical: 10,
		paddingHorizontal: 20,
		fontSize: 12,
	},
	sectionHeaderTextNormal: {
		fontWeight: 400,
	},
	sectionHeaderTextBold: {
		fontWeight: 700,
	},
	sectionBody: {
		backgroundColor: '#FFF9F0',
		display: 'flex',
		flexDirection: 'column',
		paddingHorizontal: 20,
		flex: 2,
		paddingTop: 1,
	},
	sectionColumnContainer: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		paddingTop: 12,
	},
	sectionColumnLeft: {
		width: '50%',
		paddingRight: 10,
	},
	sectionColumnRight: {
		width: '50%',
		paddingLeft: 10,
	},
	sectionColumnFull: {
		width: '100%',
	},
	sectionSubTitle: {
		fontSize: 9,
		fontWeight: 600,
		marginBottom: 5,
	},
	sectionSubText: { fontSize: 11, fontWeight: 400, marginBottom: 12 },
	listItemFull: {
		flexDirection: 'row',
		fontSize: 11,
		marginBottom: 2,
		width: '100%',
		flexBasis: '100%',
	},
	listItemThird: {
		flexDirection: 'row',
		fontSize: 11,
		marginBottom: 2,
		width: '33%',
		flexBasis: '33%',
	},
	listBullet: { marginHorizontal: 5 },
	listContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
		flexWrap: 'wrap',
	},
	greySubTitle: {
		color: '#606060',
		fontSize: 11,
		marginTop: 10,
		fontWeight: 700,
	},
});

/**
 * Subcomponents
 */

const PdfDocumentSectionHeader = ({
	boldText,
	text,
}: IPdfDocumentSectionHeader) => {
	return (
		<View style={styles.sectionHeader}>
			<Text style={styles.sectionHeaderTextBold}>{boldText}</Text>
			<Text style={styles.sectionHeaderTextNormal}>{text}</Text>
		</View>
	);
};

const PdfDocumentSectionData = ({ title, value }: IPdfDocumentSectionData) => {
	return (
		<>
			<Text style={styles.sectionSubTitle}>{title}</Text>
			<Text style={styles.sectionSubText}>{value}</Text>
		</>
	);
};

const PdfDocumentSectionDataList = ({
	title,
	value,
	labelField = 'title',
	itemWidth = 'full',
	checkedIndex = 'checked',
}: IPdfDocumentSectionDataList) => {
	return (
		<>
			<Text style={styles.sectionSubTitle}>{title}</Text>
			{value.filter((item) => item[checkedIndex] === true).length > 0 ? (
				<PdfDocumentCheckboxList
					list={value.filter((item) => item[checkedIndex] === true)}
					labelField={labelField}
					itemWidth={itemWidth}
				/>
			) : (
				<Text style={styles.sectionSubText}>N/A</Text>
			)}
		</>
	);
};

const PdfDocumentCheckboxList = ({
	list,
	labelField,
	itemWidth,
}: {
	list: IPdfDocumentCheckboxValue[];
	labelField: 'title' | 'label';
	itemWidth: 'full' | 'third';
}) => {
	return (
		<View style={styles.listContainer}>
			{list.map((item) => (
				<View
					style={
						itemWidth === 'full' ? styles.listItemFull : styles.listItemThird
					}
				>
					<Text style={styles.listBullet}>•</Text>
					<Text style={styles.sectionSubText}>{item[labelField]}</Text>
				</View>
			))}
		</View>
	);
};

const PageCounter = () => {
	return (
		<Text
			render={({ pageNumber, totalPages }) =>
				`Page ${pageNumber} / ${totalPages}`
			}
			fixed
			style={{
				position: 'absolute',
				bottom: 30,
				right: 40,
				fontSize: 8,
				color: '#c4bfb5',
			}}
		/>
	);
};

/**
 * Helper functions
 */

const formatValue = (
	value: string | boolean | undefined | Date,
	type: string = ''
): string => {
	if (!value && type !== 'boolean') return 'N/A';
	if (value instanceof Date) value = moment(value).format('YYYY-MM-DD');

	if (typeof value === 'string') {
		switch (type) {
			case 'date':
				return moment(value).format('ddd D MMMM YYYY');
			case 'time':
				const chunks = value.split(':');
				return `${chunks[0]}:${chunks[1]}`;
			case 'capitalize':
				return value.charAt(0).toUpperCase() + value.slice(1);
			default:
				return value;
		}
	} else if (typeof value === 'boolean' || type === 'boolean') {
		return value ? 'Yes' : 'No';
	}

	return value ? value : 'N/A';
};

/**
 * Main component
 */

const PdfDocument = ({ incident, logos }: IPdfDocument) => {
	const primaryLogosState = logos.find((logo) => logo.is_primary);
	const companyLogosState = logos.filter((logo) => !logo.is_primary);

	return (
		<Document>
			<Page size="A4" style={styles.page} wrap>
				{primaryLogosState && (
					<View style={styles.headerContainer}>
						<View style={styles.leftLogoContainer}>
							<Image
								src={primaryLogosState.logo.url}
								style={styles.logo}
								key={primaryLogosState.id}
							/>
							<View style={styles.titleContainer}>
								<Text style={styles.title}>Incident</Text>
								<Text style={styles.title}>Report</Text>
							</View>
						</View>
						{companyLogosState.length > 0 && (
							<View style={styles.rightLogoContainer}>
								<View style={styles.rightLogoBottomAlign}>
									{companyLogosState.map((logo) => (
										<Image
											src={logo.logo.url}
											style={styles.bottomLogo}
											key={logo.id}
										/>
									))}
								</View>
							</View>
						)}
					</View>
				)}

				<View style={styles.separator} />

				<PdfDocumentSectionHeader
					boldText="Section 1: "
					text="Incident Details"
				/>
				<View style={styles.sectionBody}>
					<View wrap={false}>
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Branch"
									value={formatValue(incident.incident_section_1.branch)}
								/>
								<PdfDocumentSectionData
									title="Ticket No"
									value={formatValue(incident.ticket_number)}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Incident #"
									value={formatValue(incident.id.toString())}
								/>
								<PdfDocumentSectionData
									title="Incident Date"
									value={formatValue(
										incident?.incident_section_1?.incident_date,
										'date'
									)}
								/>
								<PdfDocumentSectionData
									title="Incident Time"
									value={formatValue(
										incident.incident_section_1.incident_time,
										'time'
									)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Submitted By"
									value={formatValue(incident.submitted_by)}
								/>
								<PdfDocumentSectionData
									title="Submitted Date"
									value={formatValue(incident.submitted_at, 'date')}
								/>
								<PdfDocumentSectionData
									title="Submitted Time"
									value={formatValue(
										new Date(incident.submitted_at).toLocaleTimeString(
											'en-AU',
											{
												hour: '2-digit',
												minute: '2-digit',
												hourCycle: 'h24',
											}
										)
									)}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Client"
									value={formatValue(incident.client)}
								/>
								<PdfDocumentSectionData
									title="Severity"
									value={formatValue(incident.severity, 'capitalize')}
								/>
								<PdfDocumentSectionData
									title="Exact Location"
									value={formatValue(incident.incident_section_1.location)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Job Details"
									value={formatValue(incident.incident_section_1.job_details)}
								/>
								<PdfDocumentSectionData
									title="Job Description"
									value={formatValue(incident.description)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Action Taken"
									value={formatValue(incident.incident_section_1.actionTaken)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Emergency response attendance"
									value={formatValue('N/A')}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Work ceased due to unsafe conditions"
									value={formatValue(
										incident.incident_section_1.workCeasedUnsafeConditions,
										'boolean'
									)}
								/>
								<PdfDocumentSectionData
									title="Traffic controllers intervened due to unsafe conditions"
									value={formatValue(
										incident.incident_section_1.trafficControllersIntervened,
										'boolean'
									)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Would you like to enter details of an MVI of MVI3 incident?"
									value={formatValue(
										incident.incident_section_1.mv_incident,
										'boolean'
									)}
								/>
							</View>
						</View>
						{incident.incident_section_1.mv_incident && (
							<View style={styles.sectionColumnContainer}>
								<View style={styles.sectionColumnLeft}>
									<PdfDocumentSectionData
										title="Altus Registration"
										value={formatValue(
											incident.incident_section_1.altusRegistration
										)}
									/>
									<PdfDocumentSectionData
										title="Name of Driver"
										value={formatValue(incident.incident_section_1.driverName)}
									/>
								</View>
								<View style={styles.sectionColumnRight}>
									<PdfDocumentSectionData
										title="Licence # & Class"
										value={formatValue(
											incident.incident_section_1.licenseClass
										)}
									/>
									<PdfDocumentSectionData
										title="Licence Expiry"
										value={formatValue(
											incident.incident_section_1.licenseExpiry
										)}
									/>
								</View>
							</View>
						)}
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Is there a third party involved?"
									value={formatValue(
										incident.incident_section_1.thirdParty,
										'boolean'
									)}
								/>
							</View>
						</View>
						{incident.incident_section_1.thirdParty &&
							incident.incident_section_1.thirdParties &&
							incident.incident_section_1.thirdParties.map(
								(thirdParty, index) => (
									<View style={styles.sectionColumnContainer}>
										<View style={styles.sectionColumnLeft}>
											<PdfDocumentSectionData
												title={`Name of Third Party #${index + 1}`}
												value={formatValue(thirdParty.thirdParty_name)}
											/>
											<PdfDocumentSectionData
												title={`Vehicle Registration of Third Party #${
													index + 1
												}`}
												value={formatValue(
													thirdParty.thirdParty_vehicle_registration
												)}
											/>
										</View>
										<View style={styles.sectionColumnRight}>
											<PdfDocumentSectionData
												title={`Licence Number of Third Party #${index + 1}`}
												value={formatValue(
													thirdParty.thirdParty_licence_number
												)}
											/>
											<PdfDocumentSectionData
												title={`Phone Number of Third Party #${index + 1}`}
												value={formatValue(thirdParty.thirdParty_phone_number)}
											/>
										</View>
									</View>
								)
							)}
					</View>
				</View>
				<PageCounter />
			</Page>

			<Page size="A4" style={styles.page} wrap>
				<PdfDocumentSectionHeader
					boldText="Section 2: "
					text="Details of Person(s) Involved"
				/>
				<View style={styles.sectionBody}>
					<View wrap={false}>
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Number of Persons Involved"
									value={formatValue(
										incident.incident_section_2.personInvolved.length.toString()
									)}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Number of Witnesses"
									value={formatValue(
										incident.incident_section_2.witnesses.length.toString()
									)}
								/>
							</View>
						</View>
					</View>

					{incident.incident_section_2.personInvolved.map((person, index) => (
						<View wrap={false}>
							<View style={styles.separator} />
							<View style={styles.sectionColumnContainer} wrap={false}>
								<View style={styles.sectionColumnLeft}>
									<PdfDocumentSectionData
										title={`Name of Person Involved #${index + 1}`}
										value={formatValue(person.personInvolved_name)}
									/>
									<PdfDocumentSectionData
										title={`Phone Number of Person Involved #${index + 1}`}
										value={formatValue(person.personInvolved_phone_number)}
									/>
								</View>
								<View style={styles.sectionColumnRight}>
									<PdfDocumentSectionData
										title={`Start Date`}
										value={formatValue(
											person.personInvolved_start_date,
											'date'
										)}
									/>
								</View>
							</View>
						</View>
					))}
					{incident.incident_section_2.witnesses.map((witness, index) => (
						<View wrap={false}>
							<View style={styles.separator} />
							<View style={styles.sectionColumnContainer} wrap={false}>
								<View style={styles.sectionColumnLeft}>
									<PdfDocumentSectionData
										title={`Name of Witness #${index + 1}`}
										value={formatValue(witness.witness_name)}
									/>
									<PdfDocumentSectionData
										title={`Phone Number of Witness #${index + 1}`}
										value={formatValue(witness.witness_phone_number)}
									/>
									<PdfDocumentSectionData
										title={`Position of Witness #${index + 1}`}
										value={formatValue(witness.witness_position)}
									/>
								</View>
								<View style={styles.sectionColumnRight}>
									<PdfDocumentSectionData
										title={`Task of Witness #${index + 1}`}
										value={formatValue(witness.witness_task)}
									/>
								</View>
							</View>
						</View>
					))}
				</View>
				<PageCounter />
			</Page>

			<Page size="A4" style={styles.page} wrap>
				<PdfDocumentSectionHeader
					boldText="Section 3: "
					text="Injury / Incident Details"
				/>
				<View style={styles.sectionBody}>
					<View wrap={false}>
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Were there any injuries?"
									value={formatValue(
										incident.incident_section_3.injuries,
										'boolean'
									)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionDataList
									title="Part of Body Injured"
									value={incident.incident_section_3.body_injured}
									itemWidth="third"
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionDataList
									title="Injury Type"
									value={incident.incident_section_3.injury_type}
									itemWidth="third"
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionDataList
									title="Incident Mechanism"
									value={incident.incident_section_3.incident_mechanism}
									itemWidth="third"
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionDataList
									title="Incident Agency"
									value={incident.incident_section_3.incident_agency}
									itemWidth="third"
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionDataList
									title="MVI Causation"
									value={incident.incident_section_3.mvi_causation || []}
									itemWidth="third"
								/>
							</View>
						</View>
					</View>
				</View>
				<PageCounter />
			</Page>

			<Page size="A4" style={styles.page} wrap>
				<PdfDocumentSectionHeader
					boldText="Section 4: "
					text="Incident Analysis"
				/>
				<View style={styles.sectionBody}>
					<View wrap={false}>
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Severity Level"
									value={formatValue(
										incident.incident_section_4.severity_rating,
										'boolean'
									)}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Incident Category"
									value={formatValue(
										incident.incident_section_4.incident_category
											.filter((category) => category.checked)
											.map((category) => category.title)
											.join(', ')
									)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionDataList
									title="Incident Mechanism"
									value={incident.incident_section_4.incident_mechanism || []}
									itemWidth="full"
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionDataList
									title="Incident Agency"
									value={incident.incident_section_4.incident_agency}
									itemWidth="full"
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Notifiable Incident?"
									value={formatValue(
										incident.incident_section_4.notifiable_incident,
										'boolean'
									)}
								/>
								{incident.incident_section_4.notifiable_incident && (
									<>
										<PdfDocumentSectionData
											title="Reported to"
											value={formatValue(
												incident.incident_section_4.reported_to
											)}
										/>
										<PdfDocumentSectionData
											title="Reference Number"
											value={formatValue(
												incident.incident_section_4.reference_number
											)}
										/>
										<PdfDocumentSectionData
											title="Notes"
											value={formatValue(incident.incident_section_4.notes)}
										/>
									</>
								)}
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Was this a breach of Life Saving Rules?"
									value={formatValue(
										incident.incident_section_4.life_saving_rule_breach,
										'boolean'
									)}
								/>
								<PdfDocumentSectionDataList
									title="Did this result in termination of employment?"
									value={incident.incident_section_4.life_saving_rules}
									itemWidth="full"
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Was this a breach of the Code of Conduct?"
									value={formatValue(
										incident.incident_section_4.life_saving_rule_breach,
										'boolean'
									)}
								/>
							</View>
						</View>
					</View>
				</View>
				<PageCounter />
			</Page>

			<Page size="A4" style={styles.page} wrap>
				<PdfDocumentSectionHeader
					boldText="Section 5: "
					text="Incident Investigation"
				/>
				<View style={styles.sectionBody}>
					<View wrap={false}>
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Investigation Required"
									value={formatValue(
										incident.incident_section_5.incident_investigation_required,
										'boolean'
									)}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Investigator"
									value={formatValue(
										incident.incident_section_5.incident_investigation_required
											? `${incident.incident_section_5.investigator.first_name} ${incident.incident_section_5.investigator.last_name}`
											: 'N/A'
									)}
								/>
							</View>
						</View>
					</View>

					<View wrap={false}>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer}>
							<View style={styles.sectionColumnLeft}>
								<PdfDocumentSectionData
									title="Shift Start Time"
									value={formatValue(
										incident.incident_section_5.shift_start_time,
										'time'
									)}
								/>
							</View>
							<View style={styles.sectionColumnRight}>
								<PdfDocumentSectionData
									title="Percentage of Shift Worked"
									value={formatValue(
										incident.incident_section_5.percentage_of_shift_worked
									)}
								/>
							</View>
						</View>
					</View>

					<View>
						<View style={styles.separator} />
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Organisational Factors"
									value={formatValue(
										incident.incident_section_5.organisational_factors
									)}
								/>
							</View>
						</View>
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Environmental / Task Factors"
									value={formatValue(
										incident.incident_section_5.task_environmental_factors
									)}
								/>
							</View>
						</View>
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Individual / Team Factors"
									value={formatValue(
										incident.incident_section_5.individual_factors
									)}
								/>
							</View>
						</View>
						<View style={styles.sectionColumnContainer} wrap={false}>
							<View style={styles.sectionColumnFull}>
								<PdfDocumentSectionData
									title="Absent / Failed Defenses"
									value={formatValue(
										incident.incident_section_5.absent_failed_defense
									)}
								/>
							</View>
						</View>
					</View>
				</View>
				<PageCounter />
			</Page>
			<Page size="A4" style={styles.page} wrap>
				<PdfDocumentSectionHeader boldText="" text="Corrective Actions" />
				<View style={styles.sectionBody}>
					{incident.incident_section_7?.actions?.length > 0 ? (
						incident.incident_section_7?.actions?.map((action, index) => (
							<View wrap={false}>
								{index > 0 && <View style={styles.sectionSeparator} />}
								<Text style={styles.greySubTitle}>
									Corrective Action {index + 1}
								</Text>
								<View style={styles.sectionColumnContainer} wrap={false}>
									<View style={styles.sectionColumnLeft}>
										<PdfDocumentSectionData
											title="Assigned To"
											value={formatValue(
												`${action.assignee?.first_name} ${action.assignee?.last_name}`
											)}
										/>
									</View>
									<View style={styles.sectionColumnRight}>
										<PdfDocumentSectionData
											title="Completion Date"
											value={formatValue(action.completion_date, 'date')}
										/>
									</View>
								</View>
								<View style={styles.sectionColumnContainer} wrap={false}>
									<View style={styles.sectionColumnFull}>
										<PdfDocumentSectionData
											title="Action Description"
											value={formatValue(action.action_description)}
										/>
									</View>
								</View>
								<View style={styles.sectionColumnContainer} wrap={false}>
									<View style={styles.sectionColumnFull}>
										<PdfDocumentSectionDataList
											title="Hierarchy of Control"
											value={action.hierachy_of_control}
											itemWidth="third"
											checkedIndex="is_selected"
											labelField="label"
										/>
									</View>
								</View>
								<View style={styles.sectionColumnContainer} wrap={false}>
									<View style={styles.sectionColumnFull}>
										<PdfDocumentSectionData
											title="Action Taken"
											value={formatValue(action.action_taken)}
										/>
									</View>
								</View>
							</View>
						))
					) : (
						<View wrap={false}>
							<View style={styles.sectionColumnContainer}>
								<View style={styles.sectionColumnFull}>
									<Text style={styles.sectionSubTitle}>
										No Corrective Actions
									</Text>
								</View>
							</View>
						</View>
					)}
				</View>
				<PageCounter />
			</Page>
		</Document>
	);
};

export default PdfDocument;
