import React from 'react';
import { Link } from 'react-router-dom';

export interface IStatusButton {
	className?: string;
	status:
		| 'open'
		| 'closed'
		| 'draft'
		| 'low'
		| 'medium'
		| 'high'
		| 'severe'
		| 'extreme'
		| 'completed'
		| 'submitted'
		| 'not opened'
		| 'duplicate'
		| 'nuisance'
		| 'new';
	branch?: string;
	category?: string;
	incidentPath?: string;
	total?: number;
	isDashboardStatus?: boolean;
	extraLinkQuery?: string;
}

const StatusButton: React.FC<IStatusButton> = ({
	status,
	branch = '',
	category = '',
	incidentPath = '',
	total,
	className = '',
	isDashboardStatus = false,
	extraLinkQuery = '',
}) => {
	const textStyle = {
		open: 'text-[#DC3F4F]',
		closed: 'text-[#32BFA7]',
		draft: 'text-[#3D62C0]',
		low: 'text-secondary',
		medium: 'text-secondary',
		high: 'text-secondary',
		severe: 'text-secondary',
		extreme: 'text-secondary',
		completed: 'text-[#32BFA7]',
		submitted: 'text-[#3D62C0]',
		duplicate: 'text-secondary',
		nuisance: 'text-secondary',
		'not opened': 'text-[#DC3F4F]',
		new: 'text-[#3D62C0]',
	};

	const borderStyle = {
		open: 'border-[#DC3F4F] bg-[#feeeee]',
		closed: 'border-[#32bfa7] bg-[#eaf8f6]',
		draft: 'border-[#3D62C0] bg-[#d8dff2]',
		low: 'border-secondary bg-[#EEE]',
		medium: 'border-secondary bg-[#EEE]',
		high: 'border-secondary bg-[#EEE]',
		severe: 'border-secondary bg-[#EEE]',
		extreme: 'border-secondary bg-[#EEE]',
		completed: 'border-[#32BFA7] bg-[#eaf8f6]',
		submitted: 'border-[#3D62C0] bg-[#d8dff2]',
		duplicate: 'border-secondary bg-[#EEE]',
		nuisance: 'border-secondary bg-[#EEE]',
		'not opened': 'border-[#DC3F4F]',
		new: 'border-[#3D62C0] bg-[#d8dff2]',
	};

	var incident_link: JSX.Element | string = '';

	if (incidentPath === 'incidents') {
		if (branch) {
			incident_link = (
				<Link to={`/${incidentPath}?branch_name=${branch}&status=${status}`}>
					{status} {total && total}
				</Link>
			);
		}

		if (category) {
			incident_link = (
				<Link
					to={`/${incidentPath}?category=${category}&status=${status}&${extraLinkQuery}`}
				>
					{status} {total && total}
				</Link>
			);
		}
	} else {
		incident_link = (
			<>
				{status} {total && total}
			</>
		);
	}

	return (
		<div
			className={`max-w-[125px] flex justify-center items-center flex-grow-0 flex-shrink-0 relative gap-2 bg-white !rounded-3xl border ${
				isDashboardStatus
					? 'rounded-[10px] py-2 px-2 w-fit'
					: 'rounded-[5px] px-[1px] py-[4px]'
			} ${borderStyle[status]} ${className}`}
		>
			<p
				className={`flex-grow-0 flex-shrink-0 uppercase tracking-[0.88px] text-xs 2xl:text-sm w-fit text-left ${
					textStyle[status]
				} ${isDashboardStatus ? 'font-semibold' : 'font-bold'}`}
			>
				{incident_link}
			</p>
		</div>
	);
};

export default StatusButton;
