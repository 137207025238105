import './incidentSection5.module.css';

import {
	ChevronRightIcon,
	ClockIcon,
	TrashIcon,
} from '@heroicons/react/outline';
import Button from 'components/atoms/Button';
import Divider from 'components/atoms/Divider';
import Heading from 'components/atoms/Heading';
import Input from 'components/atoms/Input';
import { IMultiSelectComboBoxOption } from 'components/atoms/MultiSelectComboBox/MultiSelectComboBox';
import Text from 'components/atoms/Text';
import TextareaInput from 'components/atoms/TextareaInput';
import ToggleButtonV2 from 'components/atoms/ToggleButtonV2';
import React, { useCallback, useEffect, useState } from 'react';
import { TailSpin } from 'react-loader-spinner';
import {
	useGetIncidentSection5Mutation,
	useUpdateIncidentSection5Mutation,
	useUploadFileMutation,
} from 'redux/api/vehicleIncidents';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { closeIncidentModal } from 'redux/slices/vehicleIncidentsSlice';
import {
	IOption,
	ISection1ErrorStates,
	ISection2ErrorStates,
	ISection3ErrorStates,
	ISection4ErrorStates,
	ISection5ErrorStates,
	ITabState,
	IVehicleIncidentSection1,
	IVehicleIncidentSection2,
	IVehicleIncidentSection3,
	IVehicleIncidentSection4,
	IVehicleIncidentSection5,
	section5PercentageRadioButtons,
	IQueryReportSection,
} from 'types/VehicleIncidents';
import { disableWriteAccess } from 'utils/disableWriteAccess';
import { removeSeconds } from 'utils/removeSeconds';
import ComboBox from 'components/atoms/ComboBox';
import {
	FaFilePdf,
	FaFileWord,
	FaFileCsv,
	FaFileExcel,
	FaFileImage,
	FaFileAlt,
	FaFileAudio,
	FaFileVideo,
	FaFilePowerpoint,
} from 'react-icons/fa';
import RadioV2 from "../../atoms/RadioV2";
import ErrorSolid from "../../../assets/icons/ErrorSolid";
import {FileUploader} from "react-drag-drop-files";
import UploadIconNew from "../../../assets/icons/UploadIconNew";
import DefaultFileIcon from "../../../assets/icons/DefaultFileIcon";

export interface IIncidentSection5 {
	sectionId: number | null;
	getIncidents: (filters: string) => void;
	filters: string;
	completed: boolean;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	setErrorTabStates: React.Dispatch<React.SetStateAction<ITabState>>;
	incidentSection1: IVehicleIncidentSection1;
	incidentSection2: IVehicleIncidentSection2;
	incidentSection3: IVehicleIncidentSection3;
	incidentSection4: IVehicleIncidentSection4;
	section1ErrorStates: ISection1ErrorStates;
	section2ErrorStates: ISection2ErrorStates;
	section3ErrorStates: ISection3ErrorStates;
	section4ErrorStates: ISection4ErrorStates;
	handleValidate: () => void;
}
interface FileResponse extends File {
	id: string;
	url: string;
	ext: string;
}

interface ISection5InitialState {
	percentageShiftWorked: string;
	shiftStartTime: string;
	organisationalFactorsInput: string;
	organisationalFactors2Input: string;
	individualFactorsInput: string;
	absentDefenseInput: string;
	investigator: string;
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	attachments: FileResponse[] | File[];
}

const initialState: ISection5InitialState = {
	percentageShiftWorked: '',
	shiftStartTime: '',
	organisationalFactorsInput: '',
	organisationalFactors2Input: '',
	individualFactorsInput: '',
	absentDefenseInput: '',
	investigator: '',
	users: [],
	attachments: [],
};

const initialErrorState: ISection5ErrorStates = {
	section_5_shift_start_time: false,
	section_5_investigator: false,
};

const FileIcon: React.FC<{ ext: string }> = ({ ext }) => {
	const fileType = ext.toLowerCase();

	switch (fileType) {
		case '.pdf':
			return <FaFilePdf size="1.5em" style={{ color: '#FAA431' }} />;
		case '.docx':
			return <FaFileWord size="1.5em" style={{ color: '#FAA431' }} />;
		case '.csv':
			return <FaFileCsv size="1.5em" style={{ color: '#FAA431' }} />;
		case '.xlsx':
			return <FaFileExcel size="1.5em" style={{ color: '#FAA431' }} />;
		case '.png':
		case '.jpg':
		case '.jpeg':
			return <FaFileImage size="1.5em" style={{ color: '#FAA431' }} />;
		case '.txt':
			return <FaFileAlt size="1.5em" style={{ color: '#FAA431' }} />;
		case '.mp3':
			return <FaFileAudio size="1.5em" style={{ color: '#FAA431' }} />;
		case '.mp4':
			return <FaFileVideo size="1.5em" style={{ color: '#FAA431' }} />;
		case '.pptx':
			return <FaFilePowerpoint size="1.5em" style={{ color: '#FAA431' }} />;
		default:
			return <DefaultFileIcon width="1.5em" height="1.5em"/>;
	}
};
const IncidentSection5: React.FC<IIncidentSection5> = ({
	sectionId,
	getIncidents,
	filters,
	setErrorTabStates,
	completed,
	setSection,
	section1ErrorStates,
	section2ErrorStates,
	section3ErrorStates,
	section4ErrorStates,
	incidentSection1,
	incidentSection2,
	incidentSection3,
	incidentSection4,
	handleValidate
}) => {
	const [isInvestigation, setIsInvestigation] = useState(true);
	const [section5, setSection5] = useState(initialState);
	const [uploadFileLoader, setUploadFileLoader] = useState(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [section5ErrorState, setSection5ErrorState] =
		useState(initialErrorState);

	function isFileResponse(file: File | FileResponse): file is FileResponse {
		return 'id' in file && 'url' in file && 'ext' in file;
	}

	const [getIncidentSection5, { data, isSuccess }] =
		useGetIncidentSection5Mutation();
	const [updateIncidentSection5] = useUpdateIncidentSection5Mutation();

	const dispatch = useAppDispatch();
	const incidentSection1Id = useAppSelector(
		(state) => state.vehicleIncident.incidentSection1Id
	);
	const incidentReportId = useAppSelector(
		(state) => state.vehicleIncident.incidentReportId
	);
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const userPermission = useAppSelector((state) => state.user.permissions);

	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection5(sectionId);
		}
	}, [getIncidentSection5, sectionId]);

	const [uploadFile] = useUploadFileMutation();

	const [attachments, setAttachments] = useState<File[]>([]);

	const handleFileUpload = async (
		files: any
	) => {
		console.log(files);
		if (files.length > 0){
			// for (const file of files) {
			const formData = new FormData();
			for (let i = 0; i < files.length; i++) {
				formData.append('files', files[i]);
			}
			try {
				setUploadFileLoader(true);
				const uploadResult = await uploadFile(formData).unwrap();
				console.log(uploadResult);
				if (uploadResult){
					const newAttachments = attachments.concat(uploadResult);
					setAttachments(newAttachments);
					await updatePayload(false, newAttachments);
				}
			} catch (error) {
				console.error('File upload fail', error);
			} finally {
				setUploadFileLoader(false);
			}
		}
	};

	const handleDeleteFile = async (fileId: string | number) => {
		try {
			const updatedAttachments = attachments.filter(
				(attachment) => isFileResponse(attachment) && attachment.id !== fileId
			);
			setAttachments(updatedAttachments);
			updatePayload(false, updatedAttachments);
		} catch (error) {
			console.error('Delete file fail', error);
		}
	};

	useEffect(() => {
		if (isSuccess && data) {
			const {
				percentage_of_shift_worked,
				incident_investigation_required,
				shift_start_time,
				organisational_factors,
				task_environmental_factors,
				individual_factors,
				absent_failed_defense,
				users,
				investigator,
				attachments,
			} = data.data;

			let selectedPercentageRadioButton = percentage_of_shift_worked;
			section5PercentageRadioButtons.forEach((item) => {
				if (item.title === percentage_of_shift_worked) {
					selectedPercentageRadioButton = item.id;
				}
			});

			const formatShiftStartTime = removeSeconds(shift_start_time);

			setSection5({
				...section5,
				percentageShiftWorked: selectedPercentageRadioButton,
				shiftStartTime: formatShiftStartTime,
				organisationalFactorsInput: organisational_factors || '',
				organisationalFactors2Input: task_environmental_factors || '',
				individualFactorsInput: individual_factors || '',
				absentDefenseInput: absent_failed_defense || '',
				users,
				investigator,
				attachments,
			});

			setIsInvestigation(incident_investigation_required);

			setSection5ErrorState({
				section_5_shift_start_time: !!shift_start_time,
				section_5_investigator: !!investigator,
			});
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleSection5Update = (
		property: string,
		value: string | boolean | Date | IOption[],
	) => {
		const newSection5 = section5;
		// @ts-ignore
		newSection5[property] = value;
		setSection5(newSection5);
	};

	const validateSections = useCallback(
		(
			section:
				| ISection2ErrorStates
				| ISection1ErrorStates
				| ISection3ErrorStates
				| ISection4ErrorStates
				| ISection5ErrorStates
		) => {
			const errorArray: string[] = [];
			for (const [key, value] of Object.entries(section)) {
				if (!value) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return errorArray;
		},
		[]
	);

	const validateSection1 = useCallback(
		(section: ISection1ErrorStates) => {
			if (!incidentSection1.thirdParty) {
				return validateSections(section);
			} else {
				const section1Errors = validateSections(section);
				incidentSection1?.thirdParties?.forEach((thirdParty, index) => {
					if (!thirdParty.thirdParty_name) {
						section1Errors.push(`section 1 third party ${index + 1} name`);
					}
					if (!thirdParty.thirdParty_vehicle_registration) {
						section1Errors.push(
							`section 1 third party ${index + 1} vehicle registration`
						);
					}
					if (!thirdParty.thirdParty_licence_number) {
						section1Errors.push(
							`section 1 third party ${index + 1} licence number`
						);
					}
					if (!thirdParty.thirdParty_phone_number) {
						section1Errors.push(
							`section 1 third party ${index + 1} phone number`
						);
					}
				});

				return section1Errors;
			}
		},
		[
			incidentSection1.thirdParty,
			incidentSection1.thirdParties,
			validateSections,
		]
	);

	const validateSection2 = useCallback(
		(section: ISection2ErrorStates) => {
			if (
				!incidentSection2.witness_exists &&
				incidentSection2?.personInvolved?.length <= 0
			) {
				return validateSections(section);
			} else {
				const section2Errors = validateSections(section);
				if (incidentSection2.witness_exists) {
					incidentSection2.witnesses.forEach((witness, index) => {
						if (!witness.witness_name) {
							section2Errors.push(`section 2 witness ${index + 1} name`);
						}
						if (!witness.witness_phone_number) {
							section2Errors.push(
								`section 2 witness ${index + 1} phone number`
							);
						}
					});
				}
				if (incidentSection2?.personInvolved?.length > 0) {
					incidentSection2.personInvolved.forEach((person, index) => {
						if (!person.personInvolved_name) {
							section2Errors.push(
								`section 2 person involved ${index + 1} name`
							);
						}
						if (!person.personInvolved_phone_number) {
							section2Errors.push(
								`section 2 person involved ${index + 1} phone number`
							);
						}
					});
				}
				return section2Errors;
			}
		},
		[
			incidentSection2.witness_exists,
			incidentSection2.witnesses,
			incidentSection2.personInvolved,
			validateSections,
		]
	);

	const validateSection3 = useCallback(
		(section: ISection3ErrorStates) => {
			// if (!incidentSection3.nuisance_report && incidentSection3.injuries) {
			if (incidentSection3.injuries) {
				return validateSections(section);
			} else {
				return [];
			}
		},
		[
			// incidentSection3.nuisance_report,
			incidentSection3.injuries,
			validateSections,
		]
	);

	const validateSection4 = useCallback(
		(section: ISection4ErrorStates) => {
			const errorArray: string[] = [];
			for (const [key, value] of Object.entries(section)) {
				if (
					(key === 'section_4_reference_number' ||
						key === 'section_4_reported_to') &&
					incidentSection4.isNotifiableIncident &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_life_saving_rules' &&
					incidentSection4.isLifeSavingBreach &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_code_of_conduct_checkbox' &&
					incidentSection4.isCodeOfConductBreach &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_incident_agency' &&
					!incidentSection3.injuries &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key === 'section_4_incident_mechanism' &&
					!incidentSection3.injuries &&
					!value
				) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (key === 'section_4_incident_category' && !value) {
					errorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}
			return errorArray;
		},
		[
			incidentSection4.isNotifiableIncident,
			incidentSection4.isLifeSavingBreach,
			incidentSection4.isCodeOfConductBreach,
			incidentSection3.injuries,
		]
	);

	const validateSection5 = useCallback(
		(section: ISection5ErrorStates) => {
			const section5ErrorArray: string[] = [];

			if (isInvestigation) {
				return validateSections(section);
			}

			for (const [key, value] of Object.entries(section)) {
				if (key === 'section_5_investigator' && isInvestigation && !value) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (key === 'section_5_shift_start_time' && !value) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				} else if (
					key !== ('section_5_shift_start_time' && 'section_5_investigator') &&
					!value
				) {
					section5ErrorArray.push(`${key.replaceAll('_', ' ')}`);
				}
			}

			return section5ErrorArray;
		},
		[isInvestigation, validateSections]
	);

	useEffect(() => {
		const sectionMessages = validateSections(section1ErrorStates);
		const section1Messages = validateSection1(section1ErrorStates);
		const section2Messages = validateSection2(section2ErrorStates);
		const section3Messages = validateSection3(section3ErrorStates);
		const section4Messages = validateSection4(section4ErrorStates);
		const section5Messages = validateSection5(section5ErrorState);

		setErrorMessages([
			...sectionMessages,
			...section1Messages,
			...section2Messages,
			...section3Messages,
			...section4Messages,
			...section5Messages,
		]);

		setErrorTabStates({
			section1: section1Messages.length !== 0,
			section2: section2Messages.length !== 0,
			section3: section3Messages.length !== 0,
			section4: section4Messages.length !== 0,
			section5: section5Messages.length !== 0,
		});
	}, [
		setErrorMessages,
		validateSections,
		validateSection1,
		validateSection2,
		validateSection3,
		validateSection4,
		validateSection5,
		section1ErrorStates,
		section2ErrorStates,
		section3ErrorStates,
		section4ErrorStates,
		section5ErrorState,
		setErrorTabStates,
	]);

	const updatePayload = async (
		isReportFinished?: boolean,
		newAttachments?: FileResponse[] | File[]
	) => {
		const { investigator, shiftStartTime } = section5;

		if (!sectionId) {
			return;
		}

		let percentageOfShiftWorked = section5.percentageShiftWorked;
		section5PercentageRadioButtons.forEach((item) => {
			if (item.id === section5.percentageShiftWorked) {
				percentageOfShiftWorked = item.title;
			}
		});

		const formatShiftStartTime =
			shiftStartTime && shiftStartTime.length < 6
				? `${shiftStartTime}:00.000`
				: shiftStartTime;

		const updatedAttachments = newAttachments ?? attachments;

		const payload: IVehicleIncidentSection5 = {
			id: sectionId,
			percentage_of_shift_worked: percentageOfShiftWorked,
			organisational_factors: section5.organisationalFactorsInput,
			task_environmental_factors: section5.organisationalFactors2Input,
			individual_factors: section5.individualFactorsInput,
			absent_failed_defense: section5.absentDefenseInput,
			incident_investigation_required: isInvestigation,
			investigator,
			users: section5.users,
			shift_start_time: formatShiftStartTime,
			section1Id: incidentSection1Id,
			incidentReportId,
			attachments: updatedAttachments,
		};

		if (isReportFinished) {
			payload.isReportFinished = isReportFinished;
		}

		await updateIncidentSection5(payload);
	};

	useEffect(() => {
		if (section5.attachments && section5.attachments.length > 0) {
			setAttachments(section5.attachments);
		}
	}, [section5.attachments]);

	const handleOnBlur = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		await updatePayload();

		setSection5ErrorState({
			section_5_shift_start_time: !!section5.shiftStartTime,
			section_5_investigator: !!section5.investigator,
		});
	};

	const handleCreateIncidentSection5 = async () => {
		if (!sectionId) {
			return;
		}
		await updatePayload(true);
		dispatch(closeIncidentModal());
		getIncidents(filters);
	};

	const handleChangeAndBlur = (
		property: string,
		value: string | boolean | Date | IOption[],
	) => {
		handleSection5Update(property, value);
		handleOnBlur();
	};

	const formatBytes = (bytes: number) => {
		if (!+bytes) return '0 Bytes'

		const k = 1024
		const dm = 2 < 0 ? 0 : 2
		const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb']

		const i = Math.floor(Math.log(bytes) / Math.log(k))

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
	}

	const stack2 = (
		<div
			className="rounded-xl border-[2px] border-dashed border-secondary-100 w-full h-[96px] py-[10px] flex justify-center"
		>
			<div className="flex flex-col justify-evenly">
				<div className="flex flex-row justify-center">
					<UploadIconNew/>
				</div>
				<Heading type="h3" className="underline">Click to upload or drag and drop</Heading>
			</div>
		</div>
	);

	return (
		<>
			{errorMessages.length > 0 && (
				<div className="w-full border-[#DC3F4F] border-solid border-[2px] rounded-[8px] p-[8px] bg-red-200">
					<div className="flex flex-row gap-[8px] pb-3 ">
						<div className="flex flex-col justify-center">
							<ErrorSolid color={'#DC3F4F'}/>
						</div>
						<Heading type="h3" className="text-red-500">
							 Missing details
						</Heading>
					</div>
					<Text color="text-red-500">
						Please complete the missing details to generate this report in sections:
					</Text>
					{errorMessages.map((error, index) => {
						return (
							<li key={index} className="text-red-500 font-inter font-normal tracking-normal first-letter:capitalize">
								{error}
							</li>
						);
					})}
				</div>
			)}
			<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">Incident Investigation</Heading>
			<ToggleButtonV2
				toggle={isInvestigation}
				setToggle={setIsInvestigation}
				label="Incident Investigation Required"
				onBlur={handleOnBlur}
				isDisabled={disableIncidentWriteAccess}
			/>
			{isInvestigation && (
				<div>
					<ComboBox
						label="Investigator"
						options={section5.users}
						isDisabled={disableIncidentWriteAccess}
						required={true}
						onChange={(value: string) =>
							handleChangeAndBlur('investigator', value)
						}
						selected={section5.investigator}
						className="mb-10 w-80"
					/>
				</div>
			)}

			<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
			<div className="flex flex-col mb-[20px]">
				<Input
					type="time"
					onChange={(value: string) =>
						handleSection5Update('shiftStartTime', value)
					}
					onBlur={handleOnBlur}
					value={section5.shiftStartTime}
					className="w-80 mb-[24px]"
					label="Shift Start Time"
					Icon={ClockIcon}
					iconClassName="text-black"
					iconPosition="trailing"
					placeholder="Select"
					isDisabled={disableIncidentWriteAccess}
				/>
				<Text
					type="bold"
					className="block text-base font-bold font-inter text-secondary mb-3.5"
				>
					Portion of Shift Worked when Incident Occurred *
				</Text>
				<div className="gap-2 w-full">
					<RadioV2
						options={section5PercentageRadioButtons}
						onChange={(value: string) =>
							handleSection5Update('percentageShiftWorked', value)
						}
						onBlur={handleOnBlur}
						defaultId={section5.percentageShiftWorked || '1'}
						radioDirection="grid"
						classNameWrapper="mt-2 mb-2 text-sm"
						isDisabled={disableIncidentWriteAccess}
					/>
				</div>
			</div>
			<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
			<Heading type="h2" className="uppercase">
				What factors contributed to the incident occuring?
			</Heading>
			<div>
				<Text type="bold" className="text-sm mt-[32px] text-secondary-900 mb-[12px]">
					Organisational Factors
				</Text>
				<TextareaInput
					id="1"
					className="w-full"
					value={section5.organisationalFactorsInput}
					rows={10}
					cols={35}
					placeholder="The underlying factors in the organisation that influenced and
					produced the conditions affecting the performance in the workplace.
					Examples: No SOP for a particular task, inadequate training, staff not
					properly inducted, no process to ensure appropriate resources on the
					job, failure of fatigue management process etc."
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('organisationalFactorsInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<div>
				<Text type="bold" className="text-sm mt-[24px] text-secondary-900 mb-[12px]">
					Environmental / Task factors
				</Text>
				<TextareaInput
					id="2"
					className="w-full"
					value={section5.organisationalFactors2Input}
					rows={10}
					cols={35}
					placeholder="The local workplace factors. The conditions, circumstances or objects
					in the workplace that directly influenced human and equipment
					performance at the time or immediately prior to an incident. Examples:
					Extreme temperatures, poor light, wet weather, bend in road affecting
					visibility, no shoulder for placement of signs etc."
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('organisationalFactors2Input', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<div>
				<Text type="bold" className="text-sm mt-[24px] text-secondary-900 mb-[12px]">
					Individual / Team Factors
				</Text>
				<TextareaInput
					id="3"
					className="w-full"
					value={section5.individualFactorsInput}
					rows={10}
					cols={35}
					placeholder="The actions, errors, or violations that led directly to the incident.
					Examples: Traffic Controller crossed live lane of traffic; Traffic
					Controller stopping traffic with no Stop Bat or not wearing PPE;
					Member of public on mobile phone driving through site; Machine
					operator not communicating plant movement etc."
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('individualFactorsInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<div>
				<Text type="bold" className="text-sm mt-[24px] text-secondary-900 mb-[12px]">
					Absent / Failed Defenses
				</Text>
				<TextareaInput
					id="4"
					className="w-full"
					value={section5.absentDefenseInput}
					rows={10}
					cols={35}
					placeholder="The last minute measures which did not prevent the outcome of the
					incident or reduce its consequences. Examples: Hand brake not engaged
					(runaway vehicle); Vehicle protruding into live lane (Vehicle
					incident); TC not wearing steel capped boots (foot injury) etc."
					resize={true}
					onChange={(value: string) =>
						handleSection5Update('absentDefenseInput', value)
					}
					onBlur={handleOnBlur}
					isDisabled={disableIncidentWriteAccess}
				/>
			</div>
			<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
			<Heading type="h2" className="uppercase mb-8">
				Upload files
			</Heading>
			<div className="py-[40px] px-6 border-solid border-[1px] bg-[#FEEDD6] mb-4 rounded-md min-w-[748px] w-1/2 justify-between">
				<div className="flex flex-col">
					<FileUploader
						multiple={true}
						handleChange={handleFileUpload}
						name="file"
						children={stack2}
					/>
					<div className="mt-6">
						{attachments.length > 0 && (
							<Text type="bold" className="pb-4 text-left">
								Uploaded Files
							</Text>
						)}
						{attachments.map((attachment, index) =>
							isFileResponse(attachment) ? (
								<div key={attachment.id} className="flex mb-[16px] justify-between">
									<div className="flex flex-row">
										<div className="flex flex-col justify-center">
											<FileIcon ext={attachment.ext}/>
										</div>
										<div>
											<div
												className="text-base font-inter text-secondary-900 cursor-pointer flex-grow ml-4"
												onClick={() => window.open(attachment.url, '_blank')}
											>
												{attachment.name}
											</div>
											<div className="text-[11px] text-secondary-300 ml-4">
												{formatBytes(attachment.size)}
											</div>
										</div>
									</div>
									<div className="flex flex-col justify-center">
										<TrashIcon
											height={25}
											width={25}
											className="cursor-pointer ml-4"
											onClick={() => handleDeleteFile(attachment.id)}
										/>
									</div>
								</div>
							) : (
								<li
									key={index}
									className="block text-base font-inter text-secondary"
								>
									{attachment.name}
								</li>
							)
						)}
						<div className="flex flex-row justify-center">
							{uploadFileLoader ? (
								<TailSpin
									height="25"
									width="25"
									ariaLabel="tail-spin-loading"
									color="#FE9F00"
									wrapperClass="flex mt-2"
									radius={2}
									visible
								/>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
			<div className="flex justify-between mb-[64px]">
				<Button
					onClick={handleCreateIncidentSection5}
					className="font-bold !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
					isDisabled={errorMessages.length > 0 || disableIncidentWriteAccess}
				>
					{completed ? 'Save' : 'Create Report'}
					<ChevronRightIcon height={18} width={32}/>
				</Button>
				<div className="flex justify-end">
					<Button
						onClick={() => {
							handleValidate();
							setSection('section4');
						}}
						type="primary"
						className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px]"
					>
						Back to Analysis
					</Button>
					<Button
						onClick={() => {
							handleValidate();
							setSection('section7');
						}}
						type="primary"
						className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px]"
					>
						Continue to Corrective Actions
					</Button>
				</div>
			</div>
		</>
	);
};

export default IncidentSection5;
