import MainLayout from 'components/templates/MainLayout';
import { useEffect, useState } from 'react';
import Table from 'components/organisms/Table';
import Button from 'components/atoms/Button';
import { PlusIcon, SearchIcon, UploadIcon } from '@heroicons/react/solid';
import TabLink from 'components/atoms/TabLink';
import { IQueryPeriod } from 'types/VehicleFaults';
import Search from 'components/molecules/Search';
import SlideOver from 'components/molecules/SlideOver';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import {
	closeCommentModal,
	closeFaultModal,
	openFaultModal,
	closeActionsModal,
} from 'redux/slices/vehicleFaultSlice';
import CommentPanel from 'components/organisms/CommentPanel';
import VehiclePanel from 'components/organisms/VehiclePanel';
import VehicleFaultActionsPanel from 'components/organisms/VehicleFaultActionsPanel';
import {
	useGetVehicleFaultsMutation,
	useCreateFaultActivityMutation,
	useGetFaultActivityMutation,
	useDeleteFaultActivityMutation,
	useUpdateFaultActivityMutation,
} from 'redux/api/vehicleFaults';
import { useParams } from 'react-router-dom';
import FaultSearchBar from 'components/molecules/FaultSearchBar';
import { hasAccess } from 'utils/permissions';
import { formatName } from 'utils/formatName';
import * as XLSX from 'xlsx';

const VehicleFaults = () => {
	const [period, setPeriod] = useState<IQueryPeriod>('today');
	const [filters, setFilters] = useState<string>('period=today');
	const [getFaults, { data }] = useGetVehicleFaultsMutation();
	const [open, setOpen] = useState(false);
	const faultState = useAppSelector((state) => state.vehicleFault);
	const dispatch = useAppDispatch();
	const userPermission = useAppSelector((state) => state.user.permissions);
	const [createFaultActivity] = useCreateFaultActivityMutation();
	const [deleteFaultActivity] = useDeleteFaultActivityMutation();
	const [updateFaultActivity] = useUpdateFaultActivityMutation();
	const [getFaultActivity, { data: activityData }] =
		useGetFaultActivityMutation();
	const { uid } = useParams();

	useEffect(() => {
		if (uid) {
			setFilters(`uid=${uid}`);
		}
	}, [uid]);

	useEffect(() => {
		getFaults(filters);
	}, [filters, getFaults]);

	const handleCloseComments = () => {
		dispatch(closeCommentModal());
	};

	const handleCloseVehicleReport = () => {
		dispatch(closeFaultModal());
	};

	const handleCloseActions = () => {
		dispatch(closeActionsModal());
		getFaults(filters);
	};

	const newFault = () => {
		dispatch(openFaultModal());
	};

	const isManager = hasAccess(userPermission, 'manager');

	const hasManagerBranchAccess = () => {
		return hasAccess(
			userPermission,
			'manager',
			'fault_level',
			'write',
			faultState.faultBranchId
		);
	};

	const headings = [
		'Registration',
		'Vehicle Type',
		'Faults',
		'Priority',
		'Date',
		'Creator',
		'Branch',
		'Ticket No.',
		'Status',
	];

	const handleOpen = () => {
		setOpen(true);
	};

	const renderCsvHeadings = () => {
		if (!data?.data) return [];

		return [
			{
				value: 'Registration',
				alignment: 'left',
			},
			{
				value: 'Vehicle Type',
				alignment: 'left',
			},
			{
				value: 'Number of Faults',
				alignment: 'left',
			},
			{
				value: 'Priority',
				alignment: 'left',
			},
			{
				value: 'Date',
				alignment: 'left',
			},
			{
				value: 'Creator',
				alignment: 'left',
			},
			{
				value: 'Branch',
				alignment: 'left',
			},
			{
				value: 'Ticket No.',
				alignment: 'left',
			},
			{
				value: 'Status',
				alignment: 'left',
			},
			{
				value: 'Faults',
				alignment: 'left',
			},
		];
	};

	const formatFaultsForExport = (
		faults: { id: number; fault_type: string }[],
		separator: string
	) => {
		if (!faults || faults.length < 1) return '';
		return faults
			.map((row) => {
				return row.fault_type;
			})
			.join(separator);
	};

	const renderCsvRows = (separator: string) => {
		if (!data?.data) return [];
		if (!separator) separator = '|';
		const rows = data.data.map((row) => {
			return {
				type: 'default',
				values: [
					{
						value: row.registration,
						alignment: 'left',
					},
					{
						value: row.vehicle_type,
						alignment: 'left',
					},
					{
						value: row.numberOfFaults,
						alignment: 'left',
					},
					{
						value: row.priority,
						alignment: 'left',
					},
					{
						value: new Date(row.submitted_at)
							.toLocaleDateString('en-AU', {
								weekday: 'short',
								day: 'numeric',
								month: 'short',
							})
							.replace(',', ''),
						alignment: 'left',
					},
					{
						value: formatName(row.operator_name),
						alignment: 'left',
					},
					{
						value: row.branch.name,
						alignment: 'left',
					},
					{
						value: row.ticket_number,
						alignment: 'left',
					},
					{
						value: row.status,
						alignment: 'left',
					},
					{
						value: formatFaultsForExport(row.vehicle_faults, separator),
						alignment: 'left',
					},
				],
			};
		});
		return rows || [];
	};

	const extractText: string | number | any = (
		children: string | number | any
	) => {
		if (typeof children === 'string' || typeof children === 'number') {
			return children;
		}

		if (Array.isArray(children)) {
			return children.map((child) => extractText(child)).join(' ');
		}

		//@ts-ignore
		if (React.isValidElement(children) && children.props.children) {
			//@ts-ignore
			return extractText(children.props.children);
		}

		return '';
	};

	const generateReportName = () => {
		return `vehiclefaults-report-${new Date().toISOString().split('T')[0]}`;
	};

	const exportToExcel = () => {
		const workbook = XLSX.utils.book_new();

		const headerRow = renderCsvHeadings().map((column) => {
			return column.value;
		});

		const sheetData = [headerRow];

		const dataRows = renderCsvRows('|').map((row) => {
			return row.values.map((valueRow) => {
				return String(extractText(valueRow.value));
			});
		});

		const sheet = XLSX.utils.aoa_to_sheet(sheetData.concat(dataRows));

		XLSX.utils.book_append_sheet(workbook, sheet, 'Faults');

		XLSX.writeFile(workbook, `${generateReportName()}.xlsx`);
	};

	return (
		<MainLayout url="vehicle-faults">
			<main className="">
				<div className="flex items-center justify-center py-7 px-4 sticky top-0 z-30 bg-white">
					<Button type="secondary" onClick={handleOpen}>
						<SearchIcon className="h-5 w-5 mr-1" />
						Search
					</Button>
					<div className="flex items-center justify-center flex-1 w-full space-x-4">
						<TabLink
							onClick={() => {
								setFilters('period=today');
								setPeriod('today');
							}}
							active={period === 'today'}
						>
							Today
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=week');
								setPeriod('week');
							}}
							active={period === 'week'}
						>
							This Week
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=month');
								setPeriod('month');
							}}
							active={period === 'month'}
						>
							This Month
						</TabLink>
						<TabLink
							onClick={() => {
								setFilters('period=all');
								setPeriod('all');
							}}
							active={period === 'all'}
						>
							All
						</TabLink>
					</div>
					{isManager && (
						<Button onClick={newFault}>
							<PlusIcon className="h-5 w-5 mr-1" />
							New Fault
						</Button>
					)}
					<Button onClick={exportToExcel} className="ml-1">
						<UploadIcon className="h-5 w-5 mr-1" />
						Export XLSX
					</Button>
				</div>
				<Table
					headings={headings}
					data={data?.data}
					refetchData={() => getFaults(`period=${period}`)}
				/>
			</main>
			<Search open={open} setOpen={setOpen} searchType={'fault'}>
				<FaultSearchBar setFilters={setFilters} setOpen={setOpen} />
			</Search>
			<SlideOver open={faultState.commentsOpen} onClose={handleCloseComments}>
				<CommentPanel
					id={faultState.commentFaultId}
					onClose={handleCloseComments}
					createActivity={(payload) => createFaultActivity(payload)}
					updateActivity={updateFaultActivity}
					deleteActivity={deleteFaultActivity}
					activityData={activityData}
					getActivity={getFaultActivity}
					activityType="vehicle_fault"
					hasPermissionAccess={hasManagerBranchAccess}
				/>
			</SlideOver>
			<SlideOver
				open={faultState.vehicleFaultPanelOpen}
				onClose={handleCloseVehicleReport}
			>
				<VehiclePanel getFaults={getFaults} filters={filters} />
			</SlideOver>
			<SlideOver open={faultState.actionsOpen} onClose={handleCloseActions}>
				<VehicleFaultActionsPanel></VehicleFaultActionsPanel>
			</SlideOver>
		</MainLayout>
	);
};

export default VehicleFaults;
