import React, { SetStateAction, useEffect, useState } from 'react';

export interface IRadioV2 {
	options: { title: string; id: string; description?: string; subTitle?: string; }[];
	defaultId?: string;
	onChange: React.Dispatch<SetStateAction<any>>;
	className?: string;
	classNameWrapper?: string;
	radioDirection?: string;
	isDisabled?: boolean;
	onBlur?: () => void;
}

const RadioV2: React.FC<IRadioV2> = ({
	options,
	defaultId,
	onChange,
	className = '',
	classNameWrapper = '',
	radioDirection = '',
	isDisabled = false,
	onBlur,
}) => {
	const [selected, setSelected] = useState(defaultId);

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value;
		onChange(value);
		setSelected(value);
	};

	const handleOnBlur = () => {
		if (!onBlur) return;
		onBlur();
	};

	useEffect(() => {
		if (defaultId) setSelected(defaultId);
	}, [defaultId]);

	return (
		<form>
			<div className={`${className}`}>
				<fieldset>
					<div
						className={`${radioDirection} gap-4 ${classNameWrapper} items-baseline grid-col gap-4 grid-col grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5`}
					>
						{selected &&
							options.map((option) => (
								<label
									key={option.id}
									className={`h-full flex items-center text-sm text-secondary cursor-pointer border-[1px] border-secondary-200 p-[16px] rounded-[4px] ${option.id === selected ? 'bg-primary-200' : ''} w-full`}
								>
									<input
										id={option.id}
										name="radio"
										type="radio"
										value={option.id}
										onChange={handleOnChange}
										onBlur={handleOnBlur}
										checked={option.id === selected}
										className="focus:ring-primary-500 h-[18px] w-[18px] text-primary-500 bg-white mr-4 border-gray-300 rounded-full "
										disabled={isDisabled}
									/>
									<div>
										<div className={`text-secondary-900 ${option.id === selected ? '!font-bold' : 'font-normal'}`}>
											{option.title} <span>{option.subTitle ? option.subTitle : ''}</span>
										</div>
										{option.description && (<div className={`mt-[6px] ${option.id === selected ? '!font-bold' : 'font-normal'}`}>
											{option.description}
										</div>)}
									</div>
								</label>
							))}
					</div>
				</fieldset>
			</div>
		</form>
	);
};

export default RadioV2;
