import React from 'react';

export interface ITabLink {
	className?: string;
	children: React.ReactNode;
	onClick?: () => any;
	active?: boolean;
	disabled?: boolean;
	errorExists?: boolean;
	v2?: boolean;
}

const TabLink: React.FC<ITabLink> = ({
	children,
	active = false,
	onClick,
	className = '',
	disabled = false,
	errorExists = false,
	v2,
}) => {
	const tabStyles = errorExists
		? 'text-red-500'
		: 'text-secondary-50 hover:text-secondary-200';

	return (
		<>
			{disabled ? (
				<div className=" text-primary-700 font-bold font-inter tracking-normal">
					{children}
				</div>
			) : (
				<div
					onClick={onClick}
					className={`${v2 ? 'pb-8' : 'pb-2'} flex justify-center items-center p-2 bg-transparent cursor-pointer font-inter text-base font-bold tracking-normal
					${active ? `text-primary-500 border-b-[4px] border-primary ${v2 ? '!pb-7' : ''}` : `${tabStyles}`}
					${className}`}
				>
					{children}
				</div>
			)}
		</>
	);
};

export default TabLink;
