import {
	IOption,
	IQueryReportSection,
	IVehicleIncidentSection7, section7PriorityRadioButtons,
} from '../../../types/VehicleIncidents';
import React, { useEffect, useState } from 'react';
import Heading from '../../atoms/Heading';
import Button from '../../atoms/Button';
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import { IMultiSelectComboBoxOption } from '../../atoms/MultiSelectComboBox/MultiSelectComboBox';
import TextareaInput from '../../atoms/TextareaInput';
import Text from '../../atoms/Text';
import ComboBox from '../../atoms/ComboBox';
import DateInput from '../../atoms/DateInput';
import ToggleButtonV2 from '../../atoms/ToggleButtonV2';
import Divider from '../../atoms/Divider';
import { hasAccess } from '../../../utils/permissions';
import { useAppSelector } from '../../../redux/hooks';
import { disableWriteAccess } from '../../../utils/disableWriteAccess';
import {
	useGetIncidentSection7Mutation,
	useUpdateIncidentSection7Mutation,
} from '../../../redux/api/vehicleIncidents';
import FormCheckBoxGroupV2 from "../../molecules/FormCheckboxGroupV2";
import RadioV2 from "../../atoms/RadioV2";

interface IAction {
	id: number;
	action_description: string;
	hierachy_of_control: {
		id: number;
		title: string;
		checked: boolean;
	}[];
	assignee: string;
	action_taken: string;
	completion_date: Date;
	completed: boolean;
	priority: string;
}

interface ISection7InitialState {
	users: IMultiSelectComboBoxOption[] | { id: string; label: string }[];
	actions: IAction[];
}

const initialState: ISection7InitialState = {
	users: [],
	actions: [],
};

export interface IIncidentSection7 {
	sectionId: number | null;
	setSection: React.Dispatch<React.SetStateAction<IQueryReportSection>>;
	handleValidate: () => void;
}

const IncidentSection7: React.FC<IIncidentSection7> = ({
	sectionId,
	setSection,
	handleValidate
}) => {
	const [getIncidentSection7, { data, isSuccess }] =
		useGetIncidentSection7Mutation();
	const [section7, setSection7] = useState(initialState); //TODO: uncomment when porting
	const [updateIncidentSection7] = useUpdateIncidentSection7Mutation(); //TODO: uncomment when porting
	const userPermission = useAppSelector((state) => state.user.permissions);
	const incidentSection1Id = useAppSelector(
		(state) => state.vehicleIncident.incidentSection1Id
	);
	const incidentBranchId = useAppSelector(
		(state) => state.vehicleIncident.incidentBranchId
	);
	const incidentReportId = useAppSelector(
		(state) => state.vehicleIncident.incidentReportId
	);
	const disableIncidentWriteAccess = disableWriteAccess(
		'incident_level',
		userPermission,
		incidentBranchId
	);
	const investigatorHashTable: { [key: string]: boolean } = {};
	const investigatorOptions = section7.users
		.filter((user) => {
			return investigatorHashTable.hasOwnProperty(user.id)
				? false
				: (investigatorHashTable[user.id] = true);
		})
		.sort((a, b) => {
			return a.label > b.label ? 1 : -1;
		});

	useEffect(() => {
		if (sectionId !== null) {
			getIncidentSection7(sectionId);
		}
	}, [getIncidentSection7, sectionId]);

	useEffect(() => {
		if (isSuccess && data) {
			const { users, actions } = data.data;

			let formatActions: any[] = [];

			if (actions) {
				formatActions = actions.map((action) => ({
					id: action.id,
					action_description: action.action_description,
					action_taken: action.action_taken,
					completed: action.completed,
					completion_date: new Date(action.completion_date),
					priority: action.priority,
					assignee:
						typeof action.assignee !== 'number' && action.assignee
							? `${action.assignee?.first_name} ${action.assignee?.last_name}`
							: '',
					hierachy_of_control: action.hierachy_of_control.map((hoc) => ({
						id: hoc.id || 0,
						title: hoc.label,
						checked: hoc.is_selected,
					})),
				}));
			}

			setSection7({
				...section7,
				users,
				actions: formatActions,
			});
		}
		// eslint-disable-next-line
	}, [isSuccess, data]);

	const handleOnBlur = async () => {
		if (disableIncidentWriteAccess) {
			return;
		}

		await updatePayload();
	};

	const handleSection7Update = (
		property: string,
		value: string | boolean | Date | IOption[],
		index?: number,
		disableUpdateRequest?: boolean
	) => {
		const newSection7 = section7;

		if (index !== undefined) {
			const newActions = [...section7.actions];
			newActions[index] = {
				...newActions[index],
				[property]: value,
			};

			newSection7.actions = newActions;
			setSection7(newSection7);
		} else {
			// @ts-ignore
			newSection7[property] = value;
			setSection7(newSection7);
		}

		if (!disableUpdateRequest) {
			handleOnBlur();
		}
	};

	const updatePayload = async (newActions?: IAction[]) => {
		const { actions } = section7;

		const formattedActions: IVehicleIncidentSection7['actions'] = [];
		const tempActions = newActions ? [...newActions] : [...actions];
		for (const tempAction of tempActions) {
			let selectedPriority = '';
			section7PriorityRadioButtons.forEach((item) => {
				if (item.id === tempAction.priority) {
					selectedPriority = item.title;
				}
			});
			// Update Assignee and Hoc payload
			const assignee =
				Number(
					section7.users.find((user) => user.label === tempAction.assignee)?.id
				) || undefined;
			const hierachy_of_control = tempAction.hierachy_of_control.map((hoc) => ({
				label: hoc.title,
				name: hoc.title.toLowerCase(),
				is_selected: hoc.checked,
			}));
			const newAction = {
				...tempAction,
				assignee,
				hierachy_of_control,
				priority: selectedPriority
			};

			// To Remove duplicate
			const isExist = formattedActions.find(
				(action) =>
					action.action_description === newAction.action_description &&
					action.action_taken === newAction.action_taken &&
					action.assignee === newAction.assignee &&
					action.completed === newAction.completed
			);
			if (!isExist) {
				formattedActions.push(newAction);
			}
		}

		const payload: IVehicleIncidentSection7 = {
			id: sectionId,
			section1Id: incidentSection1Id,
			users: section7.users,
			actions: formattedActions,
			incidentReportId,
		};

		await updateIncidentSection7(payload); //TODO uncomment later
	};

	const addAction = async () => {
		if (disableIncidentWriteAccess) return;
		const { actions } = section7;
		let assignId = 0;
		if (actions && actions.length > 0) {
			assignId = actions[actions.length - 1].id + 1;
		} else {
			assignId += 1;
		}

		const addAction = [
			...section7.actions,
			{
				id: assignId,
				action_description: '',
				hierachy_of_control: [
					{
						id: -1,
						title: 'Elimination',
						checked: false,
					},
					{
						id: -2,
						title: 'Substitution',
						checked: false,
					},
					{
						id: -3,
						title: 'Isolation',
						checked: false,
					},
					{
						id: -4,
						title: 'Engineering',
						checked: false,
					},
					{
						id: -5,
						title: 'Administration',
						checked: false,
					},
					{
						id: -6,
						title: 'PPE',
						checked: false,
					},
				],
				assignee: '',
				action_taken: '',
				completion_date: new Date(),
				completed: false,
				priority: 'High',
			},
		];

		setSection7((section7) => {
			return {
				...section7,
				actions: addAction,
			};
		});

		await updatePayload(addAction);
	};

	const isCorrectiveActionsDeleteDisabled = () => {
		return !hasAccess(
			userPermission,
			'admin-manager',
			undefined,
			undefined,
			incidentBranchId
		);
	};

	const deleteAction = async (id: number) => {
		if (disableIncidentWriteAccess) return;

		const filteredList = section7.actions.filter((action) => action.id !== id);
		setSection7((prevSection7) => {
			return {
				...prevSection7,
				actions: filteredList,
			};
		});

		updatePayload(filteredList);
	};

	return (
		<div>
			<Heading type="h1" className="uppercase mb-[64px] mt-[20px]">Corrective Actions</Heading>
			{section7.actions.map((action, index) => (
				<div
					className="!mt-12 !mb-12 space-y-8 py-[24px] px-[40px] border-solid border-[1px] bg-[#FEEDD6] rounded-md">
					<div className="flex items-center justify-between space-x-4">
						<Heading type="h2" className="uppercase">Corrective Action {index + 1}</Heading>
						<Button
							type="quinary"
							className={`font-bold !rounded-3xl px-[24px] py-[10px] tracking-[1.92px] uppercase ${isCorrectiveActionsDeleteDisabled() ? 'hidden' : 'font-bold'}`}
							onClick={() => deleteAction(action.id)}
							size="md"
							isDisabled={isCorrectiveActionsDeleteDisabled()}
						>
							<TrashIcon height={20} width={18} className="cursor-pointer"/>
							&nbsp;Delete
						</Button>
					</div>
					<Text
						type="bold"
						className="font-inter leading-[26px] tracking-[-1px] text-secondary-900 flex"
					>
						Priority
						<Text className="ml-2 text-red">*</Text>
					</Text>
					<div className="gap-2 w-full">
						<RadioV2
							options={section7PriorityRadioButtons}
							onChange={(value: string) => {
								handleSection7Update('priority', value, index)
							}}
							onBlur={handleOnBlur}
							defaultId={section7PriorityRadioButtons.find((priority) => priority.title===action.priority)?.id}
							className="mt-2 mb-2 text-sm"
							classNameWrapper="gap-4"
							radioDirection={'grid'}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div className="flex flex-row">
						<TextareaInput
							id="5"
							className="w-full"
							value={action.action_description}
							rows={10}
							label={`Action`}
							required={true}
							placeholder=""
							resize={true}
							onChange={(value: string) =>
								handleSection7Update('action_description', value, index, true)
							}
							onBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div className="w-full">
						<Text
							type="bold"
							className="font-inter leading-[26px] tracking-[-1px] text-secondary-900 pb-2 flex"
						>
							Hierachy of Control
							<Text className="ml-2 text-red">*</Text>
						</Text>
						<FormCheckBoxGroupV2
							checkBoxName="hierachyControl"
							checkBoxGroupData={action.hierachy_of_control}
							onHandleChange={(name, value) =>
								handleSection7Update('hierachy_of_control', value, index)
							}
							descriptionDirection="flex-row"
							gridColumns={'4'}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div className="flex flex-row">
						<TextareaInput
							id="6"
							className="w-full"
							value={action.action_taken}
							rows={10}
							label={`Action Taken`}
							placeholder=""
							resize={true}
							onChange={(value: string) =>
								handleSection7Update('action_taken', value, index, true)
							}
							onBlur={handleOnBlur}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
					<div>
						<ComboBox
							label="Assigned to"
							options={investigatorOptions}
							isDisabled={disableIncidentWriteAccess}
							required={true}
							onChange={(value: string) =>
								handleSection7Update('assignee', value, index)
							}
							selected={action.assignee}
							className="mb-10 w-80"
						/>
					</div>
					<div>
						<DateInput
							placeholder="Select Date"
							onChange={(value: Date) =>
								handleSection7Update('completion_date', value, index)
							}
							className="w-72"
							label={`Completion Date`}
							iconClassName="text-black"
							selected={action.completion_date}
							wrapperClassName="react-datepicker-margin-0"
							isDisabled={disableIncidentWriteAccess}
							required={true}
						/>
					</div>
					<div>
						<ToggleButtonV2
							toggle={action.completed}
							onToggle={(value: boolean) =>
								handleSection7Update('completed', value, index)
							}
							label={`Completed`}
							isDisabled={disableIncidentWriteAccess}
						/>
					</div>
				</div>
			))}
			<Button
				onClick={addAction}
				type="secondary"
				className="font-bold mr-4 mt-[32px] !rounded-3xl px-[24px] py-[10px] tracking-[1.92px]"
				isDisabled={disableIncidentWriteAccess}
			>
				Add Action
				<PlusIcon height={18} width={18} className="ml-2"/>
			</Button>
			<Divider className="my-[64px]" color="!border-t-[4px] border-orange-500"/>
			<div className="flex justify-end mb-[64px]">
				<Button
					onClick={() => {
						handleValidate();
						setSection('section5');
					}}
					type="primary"
					className="font-bold mr-4 !rounded-3xl px-[40px] py-[16px] tracking-[1.92px]"
				>
					Back to Investigation
				</Button>
			</div>
		</div>
	);
};

export default IncidentSection7;
