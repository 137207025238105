import React, { MouseEventHandler, forwardRef, ForwardedRef } from 'react';
import './DateInputChildInput.css';

export interface IIcon {
	className: string;
}

export interface IDateInputChildInput {
	type: string;
	id?: string;
	placeholder?: string;
	label?: string;
	helpText?: string;
	required?: boolean;
	errorMessage?: string;
	cornerHint?: string;
	passwordHideShow?: boolean;
	Icon?: React.ComponentType<IIcon>;
	iconPosition?: 'leading' | 'trailing';
	isDisabled?: boolean;
	className?: string;
	size?: 'normal' | 'small';
	rounded?: 'medium' | 'small';
	iconClassName?: string;
	onBlur?: () => void;
	onClick?: MouseEventHandler | undefined;
}

const DateInputChildInput = (
	{
		label,
		placeholder,
		type,
		id,
		required = false,
		errorMessage,
		cornerHint,
		Icon,
		iconClassName = 'text-gray-500',
		iconPosition = 'leading',
		isDisabled = false,
		className = '',
		size = 'normal',
		rounded = 'small',
		onBlur,
		onClick = () => {},
		...props
	}: IDateInputChildInput,
	// @ts-ignore
	ref: ForwardedRef<any>
) => {
	const handleOnBlur = () => {
		if (!onBlur) return;
		onBlur();
	};

	const inputBaseStyle = errorMessage
		? 'py-3 block w-full border-red-300 focus:ring-red-500 focus:border-red-500 sm:text-sm text-primary placeholder-red-300 focus:outline-none'
		: 'py-3 block w-full border-gray-300 focus:ring-gray-300 focus:border-gray-300 focus:border-primary';

	const isLeadingIcon = () => {
		return Icon && iconPosition === 'leading';
	};

	return (
		<div className={`${className}`}>
			{label && (
				<div>
					<label
						htmlFor={id}
						className="block font-inter text-base font-bold tracking-normal text-secondary-900 mb-[12px]"
					>
						{label} {required && <span className="text-red">*</span>}
					</label>
				</div>
			)}
			<div className={'mt-1 relative rounded-md shadow-sm'}>
				{Icon && (
					<div
						className={`absolute inset-y-0 ${
							isLeadingIcon() ? 'left-0 pl-3' : 'right-0 pr-3'
						} flex items-center pointer-events-none`}
					>
						{<Icon className={`h-5 w-5 ${iconClassName}`} />}
					</div>
				)}

				<input
					type={type}
					name={id}
					id={id}
					disabled={isDisabled}
					onBlur={handleOnBlur}
					required={required}
					className={`${inputBaseStyle} ${
						isLeadingIcon() ? 'pl-10' : 'pr-10'
					} ${size === 'normal' ? 'h-12' : 'h-8'} ${
						rounded === 'medium' ? 'rounded-md' : 'rounded-sm'
					}`}
					placeholder={placeholder}
					onClick={onClick}
					ref={ref}
					{...props}
				/>
			</div>
			{errorMessage && (
				<p className="mt-2 text-sm text-red-600" id="email-error">
					{errorMessage}
				</p>
			)}
		</div>
	);
};

export default forwardRef(DateInputChildInput);
