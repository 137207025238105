import React from "react";
import {IconPropTypes} from "./ErrorSolid";

const DefaultFileIcon: React.FC<IconPropTypes> = ({color='#FAA431', width='20', height='20'}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M5.83203 3.33366C5.37179 3.33366 4.9987 3.70676 4.9987 4.16699V15.8337C4.9987 16.2939 5.37179 16.667 5.83203 16.667H14.1654C14.6256 16.667 14.9987 16.2939 14.9987 15.8337L14.9987 7.8455L10.4869 3.33366H5.83203ZM3.33203 4.16699C3.33203 2.78628 4.45132 1.66699 5.83203 1.66699H10.4869C10.9289 1.66699 11.3528 1.84259 11.6654 2.15515L16.1772 6.66699C16.4898 6.97955 16.6654 7.40348 16.6654 7.8455V15.8337C16.6654 17.2144 15.5461 18.3337 14.1654 18.3337H5.83203C4.45132 18.3337 3.33203 17.2144 3.33203 15.8337V4.16699ZM6.66536 10.0003C6.66536 9.54009 7.03846 9.16699 7.4987 9.16699H12.4987C12.9589 9.16699 13.332 9.54009 13.332 10.0003C13.332 10.4606 12.9589 10.8337 12.4987 10.8337H7.4987C7.03846 10.8337 6.66536 10.4606 6.66536 10.0003ZM6.66536 13.3337C6.66536 12.8734 7.03846 12.5003 7.4987 12.5003H12.4987C12.9589 12.5003 13.332 12.8734 13.332 13.3337C13.332 13.7939 12.9589 14.167 12.4987 14.167H7.4987C7.03846 14.167 6.66536 13.7939 6.66536 13.3337Z"
                  fill={color}/>
        </svg>
    )
}

export default DefaultFileIcon

