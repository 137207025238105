import Text from 'components/atoms/Text';
import React, { useEffect, useState } from 'react';

export interface IOption {
	id: number;
	title: string;
	checked: boolean;
	description?: string;
}

export interface ICheckBoxV2 {
	isDisabled?: boolean;
	onChange: (id: number, checked: boolean) => void;
	className?: string;
	descriptionClassName?: string;
	descriptionDirection?: 'flex-row' | 'flex-col';
	item: IOption;
	onBlur?: () => void;
}

const CheckBoxV2: React.FC<ICheckBoxV2> = ({
	isDisabled = false,
	onChange,
	className = '',
	descriptionClassName = '',
	item,
	descriptionDirection = '',
	onBlur,
}) => {
	const [checked, setChecked] = useState(item.checked);

	useEffect(() => {
		setChecked(item.checked);
	}, [item.checked]);

	return (
		<div
			onClick={(e) => {
				onChange(item.id, checked);
				setChecked(!checked);
			}}
			onBlur={onBlur}
			id={`${item.id}`}
			onSelect={onBlur}
			tabIndex={-1}
			className={`flex ${descriptionDirection} cursor-pointer mt-2 overflow-hidden border-[1px] border-secondary-200 w-[100%] p-[16px] rounded-[4px] ${checked ? 'bg-primary-200' : ''}`}
		>
			<div className={`flex flex-row text-sm`}>
				<input
					id={`${item.id}`}
					aria-describedby={`${item.title}-checkbox`}
					name={`${item.title}`}
					type="checkbox"
					checked={checked}
					disabled={isDisabled}
					className="focus:ring-primary-500 h-[18px] w-[18px] text-primary-500  rounded bg-white mr-4 border-gray-300 mt-0.5"
				/>
				<Text className={`flex flex-1 m-0 capitalize text-secondary-900 ${checked ? '!font-bold !text-secondary-900' : 'font-normal'}`}>{item.title}</Text>
			</div>
			{item.description && (
				<Text
					className={`${descriptionClassName} ${checked ? '!font-bold' : 'font-normal'} text-secondary-300 text-xs my-3`}
				>
					{item.description}
				</Text>
			)}
		</div>
	);
};

export default CheckBoxV2;
